import React, { useEffect, useState, useRef } from "react";
import { BsChatLeftText } from "react-icons/bs";
import { HiOutlineLightBulb } from "react-icons/hi";
import { RiSendPlaneLine } from "react-icons/ri";
import { AiOutlineMore } from "react-icons/ai";
import { IoPlay } from "react-icons/io5";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import defualtImg from "../../images/default.jpeg";
import { HiSpeakerXMark } from "react-icons/hi2";

const VideoCardReals = ({
  story,
  toggleDescription,
  usubscribeGroup,
  buysubscribeGroup,
  storyid,
  setDarkBackgroundToRead,
  darkBackgroundToRead,
}) => {
  const useVideoCard = useRef(null);
  const videoRef = useRef(null);
  const [paused, setPaused] = useState(true);
  const [isShare, setIsShare] = useState(false);
  const [videoAudio, setVideoAudio] = useState(false); // Start with muted sound
  const [showFullDescription, setShowFullDescription] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    const video = videoRef.current;
    const container = useVideoCard.current;

    const handleIntersection = (entries) => {
      if (entries[0].isIntersecting) {
        video.muted = false;
        setVideoAudio(false);
        video.play().catch((error) => {
          console.error("Failed to play video:", error);
          video.muted = true; // Mute if it fails to play with sound
          setVideoAudio(true);
          video.play();
        });
        setPaused(false);
      } else {
        video.pause();
        setPaused(true);
      }
    };

    const options = {
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (container) {
      observer.observe(container);
    }

    return () => {
      if (container) {
        observer.unobserve(container);
      }
    };
  }, [storyid]);

  const playHandler = (id) => {
    const video = document.getElementById(id);
    if (video.muted) {
      video.muted = false; // Unmute when the user interacts
      setVideoAudio(false);
    }

    video
      .play()
      .then(() => setPaused(false))
      .catch((error) => {
        console.error("Failed to play video on user interaction:", error);
        setPaused(true);
      });
  };

  const toUrl = (url) => {
    let isLogin = JSON.parse(localStorage.getItem("userDataSessionWay"));
    if (isLogin) {
      if (isLogin.loginUser._id) {
        history.push(`/${url}`);
      } else {
        dispatch({ type: "OpenNavModel", payload: url });
      }
    } else {
      dispatch({ type: "OpenNavModel", payload: url });
    }
  };

  function copyURL() {
    const urlText = document.querySelector(".url").textContent;

    navigator.clipboard.writeText(urlText).then(
      () => {
        alert("URL copied to clipboard!");
        document.getElementById("copy_url").innerText = "URL Copied";
      },
      (err) => {
        alert("Failed to copy URL: ", err);
      },
    );

    setTimeout(() => {
      document.getElementById("copy_url").innerText = "Copy URL";
    }, 3000);
  }

  return (
    <div
      className="video-card"
      id={`${storyid}`}
      ref={useVideoCard}
      onClick={() => playHandler(`v${storyid}`)}
    >
      <div
        style={{
          filter: `${
            darkBackgroundToRead ? "brightness(55%)" : "brightness(1)"
          }`,
          height: "100%",
          position: "relative",
        }}
      >
        {story.story_url_quality_versions.url_360 &&
          story.video_encode_processed && (
            <video
              src={story.story_url_quality_versions.url_720}
              alt="reals video"
              className="video-player"
              loop
              playsInline
              preload="metadata"
              id={`v${storyid}`}
              ref={videoRef}
            >
              Your browser does not support the video tag.
            </video>
          )}

        {!story.video_encode_processed && (
          <video
            src={story.story_url}
            alt="reals video"
            className="video-player"
            loop
            playsInline
            preload="metadata"
            id={`v${storyid}`}
            ref={videoRef}
          >
            Your browser does not support the video tag.
          </video>
        )}
      </div>

      <div
        style={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
          height: "100%",
        }}
      >
        {paused && (
          <IoPlay
            style={{ fontSize: "3rem" }}
            onClick={() => playHandler(`v${storyid}`)}
          />
        )}
      </div>

      <div className="speakmute cursor-pointer">
        {console.log(videoAudio, "speakmute")}
        {videoAudio && <HiSpeakerXMark size={25} style={{ color: "white" }} />}
      </div>

      {story._id && (
        <div className="thumbs-wrapper" style={{ overflow: "hidden" }}>
          <div className="thumb-icons">
            <div className="thumb-icons-flex">
              <div className="thumb-icons-items mt-2 cursor-pointer">
                <BsChatLeftText
                  size={25}
                  onClick={() =>
                    toUrl(
                      `chat?user_id=${story.user_id._id}&profile_id=${story.profile_id}`,
                    )
                  }
                />
              </div>

              <div className="thumb-icons-items mt-2 cursor-pointer">
                <HiOutlineLightBulb
                  size={30}
                  onClick={() => {
                    // toUrl("mycourses");
                    history.push(`/courses`);
                  }}
                />
              </div>

              <div className="thumb-icons-items mt-2 cursor-pointer">
                <RiSendPlaneLine
                  size={25}
                  onClick={() => setIsShare(!isShare)}
                />
              </div>

              <div className="thumb-icons-items mt-2 cursor-pointer">
                <AiOutlineMore size={30} style={{ fontWeight: "600" }} />
              </div>
            </div>
          </div>
          <div>
            <div className="thumb-parent">
              <div className="image-thumb-wrapper">
                <div>
                  <div className="display-flex">
                    <img
                      src={story.user_id.imageUrl}
                      alt="imagename"
                      className="image-thb"
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = defualtImg;
                      }}
                    />
                    <div className="title-card">
                      {" "}
                      {story.profiles && story.profiles.profile_name}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {story.following ? (
                  <button
                    className="btn btn-sub"
                    onClick={(e) => {
                      usubscribeGroup(story.user_id._id, e);
                    }}
                  >
                    Subscribed
                  </button>
                ) : (
                  <button
                    className="btn btn-sub"
                    style={{ padding: "5px 10px", width: "auto" }}
                    onClick={(e) => {
                      buysubscribeGroup(story.user_id._id, e);
                    }}
                  >
                    Subscribe
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {story._id && (
        <div
          className={`thumb-reel-description-wrapper
            ${
              story.story_language_id.code === "ar" ? "text-right" : "text-left"
            }
          ${showFullDescription && "thumb-reel-description-wrapper-hieght-set"}
            `}
          onClick={() => {
            setShowFullDescription(!showFullDescription);
            setDarkBackgroundToRead(!darkBackgroundToRead);
          }}
        >
          <div
            style={{
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div
              className={`${
                showFullDescription
                  ? "thumb-title-card-read-more"
                  : "thumb-title-card"
              }`}
            >
              {parse(story.story_title)}
            </div>
            <div
              className={`${
                showFullDescription
                  ? "thumb-reel-description-read-more-scroll"
                  : "thumb-reel-description"
              }`}
            >
              {parse(story.story_text)}
            </div>
          </div>
        </div>
      )}
      {isShare && (
        <div className="sharurl">
          <button
            id="closeFooterBtn"
            className="close-btn"
            onClick={() => setIsShare(false)}
          >
            X
          </button>
          <span className="url">{`${process.env.REACT_APP_URL}/story/${story._id}`}</span>
          <button onClick={copyURL} id="copy_url">
            Copy URL
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoCardReals;
