import React, { useEffect, useRef, useCallback } from "react";

function useHookWithRefCallback(url) {
  const ref = useRef(null);
  const setRef = useCallback(
    (node) => {
      if (ref.current) {
        // Make sure to cleanup any events/references added to the last instance
      }

      if (node) {
        node.pause();
        node.src = url;
        node.play();
        // Check if a node is actually passed. Otherwise node would be null.
        // You can now do what you need to, addEventListeners, measure, etc.
      }
      // Save a reference to the node
      ref.current = node;
    },
    [url]
  );

  return [setRef];
}

const NormalVDPlayer = ({ story_url }) => {
  const [ref] = useHookWithRefCallback(story_url);

  return (
    <video
      controls
      ref={ref}
      style={{
        width: "100%",
        borderRadius: "10px",
        position: "absolute",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
      }}
    >
      <source type="video/mp4" />
    </video>
  );
};

export default NormalVDPlayer;
