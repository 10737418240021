import React, { useEffect, useRef, useState } from "react";
import {
  FaPlay,
  FaPause,
  FaVolumeUp,
  FaVolumeOff,
  FaCog,
  FaExpand,
  FaCompress,
} from "react-icons/fa";

import { BsCheck2 } from "react-icons/bs";
import "./player.css";

import video from "../../images/youtube.png";

const VPlayer = ({ qualities }) => {
  const [play, setPlay] = useState(false);
  const [volume, setVolume] = useState(1);
  const [volumeSliderValue, setVolumeSliderValue] = useState(1);
  const [expand, setExapand] = useState(false);
  const [qualityShow, setQualityShow] = useState(false);
  const [selctedQuality, setSelctedQuality] = useState(null);
  const videoref = useRef(null);
  const videaWrapperRef = useRef(null);
  const progressBarRef = useRef(null);

  useEffect(() => {
    setSelctedQuality(0);
    const chekFullScreen = () => {
      if (!document.fullscreenElement) {
        setExapand(false);
      }
    };

    document.addEventListener("webkitfullscreenchange", chekFullScreen);
    document.addEventListener("mozfullscreenchange", chekFullScreen);
    document.addEventListener("fullscreenchange", chekFullScreen);

    return () => {
      document.removeEventListener("webkitfullscreenchange", chekFullScreen);
      document.removeEventListener("mozfullscreenchange", chekFullScreen);
      document.removeEventListener("fullscreenchange", chekFullScreen);
    };
  }, []);

  useEffect(() => {
    if (qualities.length > 0) {
      playSelectedQuality(0, qualities[0].url, 0);
    }
  }, [qualities.length > 0 && qualities[0].url]);

  // useEffect(() => {
  //   if (navigator.connection && !!navigator.connection.effectiveType) {
  //     console.log(navigator.connection.effectiveType);
  //   }

  //   const displayNetworkInfo = () => {
  //     if (navigator.connection && !!navigator.connection.effectiveType) {
  //       console.log(navigator.connection.effectiveType);
  //     }
  //   };

  //   navigator.connection.addEventListener("change", displayNetworkInfo);
  // });

  const togglePlayvideo = () => {
    setPlay(!play);

    if (play) {
      videoref.current.pause();
    } else {
      videoref.current.play();
    }
  };

  const toggleVlume = (volume) => {
    setVolume(volume);
    videoref.current.volume = volume;
    setVolumeSliderValue(volume);
  };

  const volumeContorl = (e) => {
    setVolume(e.target.value);
    if (e.target.value == 0) setVolume(0);
    videoref.current.volume = e.target.value;
  };

  let turnFullscreen = (fullscreen) => {
    setExapand(fullscreen);
    if (fullscreen) {
      if (videaWrapperRef.current.requestFullScreen) {
        videaWrapperRef.current.requestFullScreen();
      } else if (videaWrapperRef.current.webkitRequestFullScreen) {
        videaWrapperRef.current.webkitRequestFullScreen();
      } else if (videaWrapperRef.current.mozRequestFullScreen) {
        videaWrapperRef.current.mozRequestFullScreen();
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msCancelFullScreen) {
        document.msCancelFullScreen();
      }
    }
  };

  const progressBar = (e) => {
    let progress = (100 / e.target.duration) * e.target.currentTime;
    document
      .querySelector(".progress__current")
      .setAttribute("style", `width:${progress}%`);
  };

  const progressMoveOnClick = (e) => {
    let goToTime =
      (e.nativeEvent.offsetX / progressBarRef.current.offsetWidth) *
      videoref.current.duration;
    videoref.current.currentTime = goToTime;
  };

  const playSelectedQuality = (
    qualityIndex,
    selectedQualityUrl,
    currentTime
  ) => {
    setSelctedQuality(qualityIndex);
    setQualityShow(false);
    videoref.current.src = selectedQualityUrl;

    if (currentTime) videoref.current.currentTime = currentTime;

    if (!play) {
      videoref.current.pause();
    } else {
      videoref.current.play();
    }
  };

  return (
    <div
      className={`video-container ${expand && ""}`}
      ref={videaWrapperRef}
      style={{ position: "relative", paddingTop: "56.25%" }}
    >
      <video
        className="video-container__video"
        ref={videoref}
        onTimeUpdate={progressBar}
        style={{
          width: "100%",
          borderRadius: "10px",
          position: "absolute",
          top: "0",
          left: "0",
          height: "100%",
          width: "100%",
        }}
      >
        {qualities.length > 0 && (
          <source src={qualities[0].url} type="video/mp4" />
        )}
      </video>
      <div className="video-container__controls">
        <div
          className="video--progress"
          onClick={(e) => progressMoveOnClick(e)}
          ref={progressBarRef}
        >
          <div className="progress__current"></div>
        </div>

        <button className="control control--play paused">
          {!play && (
            <FaPlay className="icons-color " onClick={togglePlayvideo} />
          )}
          {play && (
            <FaPause className="icons-color " onClick={togglePlayvideo} />
          )}
        </button>

        <button className="control control--volume">
          <div className="control--volume__button">
            {volume == 0 && (
              <FaVolumeOff
                className="icons-color "
                onClick={() => {
                  toggleVlume(1);
                }}
              />
            )}
            {volume !== 0 && (
              <FaVolumeUp
                className="icons-color"
                onClick={() => {
                  toggleVlume(0);
                }}
              />
            )}
          </div>
          <input
            className="control--volume__slider"
            type="range"
            min="0"
            max="1"
            step="0.01"
            onInput={(e) => volumeContorl(e)}
          />
        </button>

        {qualities.length > 0 && (
          <button
            className="control control--quality"
            onClick={() => {
              setQualityShow(!qualityShow);
            }}
          >
            <FaCog className="icons-color" />
          </button>
        )}

        {qualityShow && qualities.length > 0 && (
          <div className="video-quality-section">
            {qualities.map((list, index) => {
              return (
                <div
                  onClick={() => {
                    playSelectedQuality(
                      index,
                      list.url,
                      videoref.current.currentTime
                    );
                  }}
                  key={list.quality}
                  style={{ margin: "0", padding: "0" }}
                >
                  {list.quality == "1080p" ? (
                    <div className="quality-list">
                      {index === selctedQuality && (
                        <BsCheck2 className="mr-1" />
                      )}
                      {list.quality} <sup>HD</sup>
                    </div>
                  ) : (
                    <div className="quality-list">
                      {index === selctedQuality && (
                        <BsCheck2 className="mr-1" />
                      )}
                      {list.quality}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
        <button className="control control--fullscreen">
          {!expand && (
            <FaExpand
              className="icons-color"
              onClick={() => turnFullscreen(true)}
            />
          )}
          {expand && (
            <FaCompress
              className="icons-color"
              onClick={() => turnFullscreen(false)}
            />
          )}
        </button>
      </div>
    </div>
  );
};

export default VPlayer;
