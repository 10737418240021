import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClose, AiOutlineBell } from "react-icons/ai";
import { HiBellAlert } from "react-icons/hi2";
import { BsFillChatDotsFill } from "react-icons/bs";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import ReactPlayer from "react-player";
import { fetchStory, buySubscriptionGroup } from "../../functions/story/story";
import { responseMessageDispaly } from "../../utils/sharedfunction";
import {
  getDataPostWithOutHeader,
  getDataPostWithHeader,
  getRequestedData,
} from "../../functions/editProfile/editProfile";
import LoadingCard from "../../Components/cards/LoadingCard";
import { Link } from "react-router-dom";

import defualtImg from "../../images/Read&ShareThumbnail.png";
import HlsPlayer from "../players/HlsPlayer";
import VPlayer from "../players/VPlayer";
import NormalVDPlayer from "../players/NormalVDPlayer";
const weburl = process.env.REACT_APP_BACKEND_URL;

const ViewStoryDesktop = ({ user, params_id, setHomeNavbarcollapse }) => {
  const dateoptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const [story, setStory] = useState({});
  const [youtubeVideoId, setYoutubeVideoID] = useState({});
  const [storyPage, setStoryPage] = useState(1);
  const [totalStoryPage, setTotalStoryPage] = useState();
  const [loading, setLoading] = useState();
  const [showSignin, setShowSignin] = useState(false);
  const [showDummyCard, setShowDummyCard] = useState(false);
  const [HLSVIDEOPLAYER, setHLSVIDEOPLAYER] = useState(true);
  const [qualities, setQualities] = useState([]);
  const [isFollow, setIsFollow] = useState(false);
  const [isNotifyMeAll, setIsNotifyMeAll] = useState(false);
  const dispatch = useDispatch();
  const { CatStories } = useSelector((state) => ({ ...state }));
  const playerRef = useRef(null);
  const LoginToSubRef = useRef(null);

  useEffect(() => {
    storyDetails(params_id);
    setHomeNavbarcollapse(true);
  }, [params_id, user.userData]);

  useEffect(() => {
    document.addEventListener("scroll", handleScrolListner);
    return () => {
      document.removeEventListener("scroll", handleScrolListner);
    };
  }, [storyPage, totalStoryPage]);

  useEffect(() => {
    console.log("story quality", story.story_url_quality_versions);
    if (story.story_url_quality_versions)
      if (story.story_url_quality_versions.url_360) {
        let qualties = [];
        for (let i in story.story_url_quality_versions) {
          qualties.push({
            quality: i.split("_")[1] + "p",
            url: story.story_url_quality_versions[i],
          });
        }

        setQualities(qualties.reverse());
      }
  }, [story]);

  const handleScrolListner = () => {
    const TotalPage = totalStoryPage || 1;
    const scrolled = window.scrollY;
    const scrollable =
      document.documentElement.scrollHeight - window.innerHeight;

    if (Math.ceil(scrolled) >= scrollable) {
      if (storyPage <= TotalPage) {
        if (story.category_id) {
          loadStoriesBasedOnCategoryHandler(story.category_id, storyPage);
        }
      }
    }
  };

  const storyDetails = async (story_id) => {
    try {
      let url = "";
      if (user.userData) {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getStory?story_id=${story_id}&user_id=${user.userData.loginUser._id}`;
      } else {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getStory?story_id=${story_id}`;
      }

      const res = await getRequestedData(url);

      if (res.data.body.story.story_type === "video_url") {
        const videoId = getId(res.data.body.story.story_url);
        setYoutubeVideoID(videoId);
      }

      setStory(res.data.body.story);

      console.log(res.data.body.story.story_url);

      if (res.data.body.story.story_converted_url !== "") {
        setHLSVIDEOPLAYER(true);
      } else {
        setHLSVIDEOPLAYER(false);
      }
      setIsFollow(res.data.body.isFollow);
      setIsNotifyMeAll(res.data.body.isNotifyMeAll);
      loadStoriesBasedOnCategoryHandler(res.data.body.story.category_id, 1);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  const loadStoriesBasedOnCategoryHandler = async (categoryId, storyPageNo) => {
    setLoading(true);
    let url = "";
    if (user.userData) {
      url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getCategoryBasedFreeStories?category_id=${categoryId}&user_id=${user.userData.loginUser._id}&storyIdToSkip=${params_id}&page=${storyPageNo}&storyType=video`;
    } else {
      url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getCategoryBasedFreeStories?category_id=${categoryId}&storyIdToSkip=${params_id}&page=${storyPageNo}&storyType=video`;
    }

    try {
      let res = await getRequestedData(url);
      setStoryPage(storyPageNo + 1);
      if (storyPageNo > 1) {
        dispatch({
          type: "cat_stories_append",
          payload: res.data.body.getStoryModel,
        });
      } else {
        dispatch({ type: "cat_stories", payload: res.data.body });
      }
      setTotalStoryPage(res.data.body.Total_Page);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const subscribeGroup = () => {
    setShowSignin(true);
  };

  const closeDummyCard = () => setShowDummyCard(false);

  const usubscribeGroup = async (follower_user_id, e) => {
    if (user.isLogin) {
      if (e.target.innerHTML === "Subscribed") {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/UnFollow`;
        const data = {
          user_id: user.userData.loginUser._id,
          follower_user_id: follower_user_id,
        };
        try {
          let res = await buySubscriptionGroup(url, data, user.userData.token);
          e.target.innerHTML = "Subscribe";
          setIsFollow(res.data.body.isFollow);
          setIsNotifyMeAll(res.data.body.isNotifyMeAll);
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        }
      } else {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/userFollow`;
        const data = {
          user_id: user.userData.loginUser._id,
          follower_user_id: follower_user_id,
        };
        try {
          let res = await buySubscriptionGroup(url, data, user.userData.token);
          e.target.innerHTML = "Subscribed";
          console.log(res.data.body);
          setIsFollow(res.data.body.isFollow);
          setIsNotifyMeAll(res.data.body.isNotifyMeAll);
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        }
      }
    }
  };

  const buysubscribeGroup = async (follower_user_id, e) => {
    if (user.isLogin) {
      if (e.target.innerHTML === "Subscribe") {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/userFollow`;
        const data = {
          user_id: user.userData.loginUser._id,
          follower_user_id: follower_user_id,
        };
        try {
          let res = await buySubscriptionGroup(url, data, user.userData.token);

          e.target.innerHTML = "Subscribed";
          setIsFollow(res.data.body.isFollow);
          setIsNotifyMeAll(res.data.body.isNotifyMeAll);
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        }
      } else {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/UnFollow`;
        const data = {
          user_id: user.userData.loginUser._id,
          follower_user_id: follower_user_id,
        };
        try {
          let res = await buySubscriptionGroup(url, data, user.userData.token);
          e.target.innerHTML = "Subscribe";
          setIsFollow(res.data.body.isFollow);
          setIsNotifyMeAll(res.data.body.isNotifyMeAll);
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        }
      }
    } else {
      setShowSignin(true);
      setTimeout(function () {
        setShowSignin(false);
      }, 6000);
    }
  };

  const notifyHandler = async (notifyMeAll) => {
    if (user.isLogin) {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/notifyMeWhenUserEachUpdates`;
      const data = {
        user_id: user.userData.loginUser._id,
        subscriber_user_id: story.user_id._id,
        notifyMeAll: notifyMeAll,
      };
      try {
        let res = await getDataPostWithHeader(url, data, user.userData.token);
        setIsNotifyMeAll(res.data.body.isNotifyMeAll);
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      }
    }
  };

  return (
    <>
      <div className="container-fluid" style={{ marginTop: "60px" }}>
        <div className="row mt-3 mr-5 no-margin-mobile-left no-margin-mobile-right">
          <div
            className="col-md-8"
            style={{ paddingRight: "7px", paddingLeft: "7px" }}
          >
            {story._id && story.story_type == "video" && (
              <>
                {console.log("story", story)}
                <div
                  style={{
                    width: "100%",
                    // height: 0,
                    // paddingBottom: '56.25%', // 16:9 Aspect Ratio
                    // position: 'relative',
                    // backgroundColor: '#F6F6F6', // Just for visibility
                  }}
                >
                  <div
                    style={{
                      // position: 'absolute',
                      // top: 0,
                      // left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                      className={`${
                        story.aspectRatio !== "16:9" && "height-video-story"
                      }`}
                    >
                      {story.story_converted_url !== "" &&
                        HLSVIDEOPLAYER &&
                        story.video_encode_processed &&
                        story.video_encode_process_status === "completed" && (
                          <HlsPlayer
                            url={story.story_converted_url}
                            setHLSVIDEOPLAYER={setHLSVIDEOPLAYER}
                          />
                        )}

                      {story.story_url_quality_versions.url_360 !== "" &&
                        !HLSVIDEOPLAYER &&
                        story.video_encode_processed && (
                          <VPlayer qualities={qualities} />
                        )}

                      {!story.video_encode_processed && (
                        <div>
                          <NormalVDPlayer story_url={story.story_url} />
                        </div>
                      )}

                      {story.story_converted_url === "" &&
                        story.story_url_quality_versions.url_360 === "" &&
                        story.video_encode_processed && (
                          <div>
                            <NormalVDPlayer story_url={story.story_url} />
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                {/* <div
                  className='mt-3'
                  style={{
                    fontSize: '16px',
                    fontWeight: '400',
                    color: 'black',
                  }}
                >
                  {story.story_title}
                </div>
                <div
                  className='mt-2'
                  style={{
                    fontSize: '14px',
                    fontWeight: '300',
                    color: '#737373',
                  }}
                >
                  {story.read.count} Views -{' '}
                  {new Date(story.createdAt).toLocaleDateString(
                    'en-US',
                    dateoptions
                  )}
                </div>
                <hr /> */}
              </>
            )}

            {story._id && story.story_type == "text" && (
              <>
                <div className="image-warapper-ratio-adujestment">
                  {story.thumbnail_url !== "" && (
                    <img
                      className="image-whereplayer-shows"
                      src={story.thumbnail_url}
                      alt="im-story"
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = defualtImg;
                      }}
                    />
                  )}
                </div>
                {/* <div
                  className='mt-3'
                  style={{
                    fontSize: '16px',
                    fontWeight: '400',
                    color: 'black',
                  }}
                >
                  {story.story_title}
                </div>
                <div
                  className='mt-2'
                  style={{
                    fontSize: '14px',
                    fontWeight: '300',
                    color: '#737373',
                  }}
                >
                  {story.read.count} Views -
                  {new Date(story.createdAt).toLocaleDateString(
                    'en-US',
                    dateoptions
                  )}
                </div>
                <hr /> */}
              </>
            )}

            {story._id && story.story_type == "image" && (
              <>
                <div className="image-warapper-ratio-adujestment">
                  <img
                    className="image-whereplayer-shows"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                    }}
                    src={story.thumbnail_url}
                    alt="im-story"
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = defualtImg;
                    }}
                  />
                </div>
                {/* <div
                  className='mt-3'
                  style={{
                    fontSize: '16px',
                    fontWeight: '400',
                    color: 'black',
                  }}
                >
                  {story.story_title}
                </div>
                <div
                  className='mt-2'
                  style={{
                    fontSize: '14px',
                    fontWeight: '300',
                    color: '#737373',
                  }}
                >
                  {story.read.count} Views -
                  {new Date(story.createdAt).toLocaleDateString(
                    'en-US',
                    dateoptions
                  )}
                </div>
                <hr /> */}
              </>
            )}

            {story._id && story.story_type == "audio" && (
              <>
                <audio
                  controls
                  src={story.story_url}
                  className="story-margin "
                  style={{ width: "100%" }}
                ></audio>
                {/* <div
                  className='mt-3'
                  style={{
                    fontSize: '16px',
                    fontWeight: '400',
                    color: 'black',
                  }}
                >
                  {story.story_title}
                </div>
                <div
                  className='mt-2'
                  style={{
                    fontSize: '14px',
                    fontWeight: '300',
                    color: '#737373',
                  }}
                >
                  {story.read.count} Views -{' '}
                  {new Date(story.createdAt).toLocaleDateString(
                    'en-US',
                    dateoptions
                  )}
                </div>
                <hr /> */}
              </>
            )}
            {story._id && story.story_type == "video_url" && (
              <>
                <div style={{ position: "relative", paddingTop: "56.25%" }}>
                  <iframe
                    src={`https://www.youtube.com/embed/${youtubeVideoId}?autoplay=1`}
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      height: "100%",
                      width: "100%",
                    }}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen="allowfullscreen"
                    mozallowfullscreen="mozallowfullscreen"
                    msallowfullscreen="msallowfullscreen"
                    oallowfullscreen="oallowfullscreen"
                    webkitallowfullscreen="webkitallowfullscreen"
                  ></iframe>
                </div>
                {/* <div
                  className='mt-3'
                  style={{
                    fontSize: '16px',
                    fontWeight: '400',
                    color: 'black',
                  }}
                >
                  {story.story_title}
                </div>
                <div
                  className='mt-2'
                  style={{
                    fontSize: '14px',
                    fontWeight: '300',
                    color: '#737373',
                  }}
                >
                  {story.read.count} Views -{' '}
                  {new Date(story.createdAt).toLocaleDateString(
                    'en-US',
                    dateoptions
                  )}
                </div>
                <hr /> */}
              </>
            )}

            {story._id && (
              <div
                className="mt-2"
                style={{
                  fontSize: "14px",
                }}
              >
                {console.log(story)}
                <div>
                  <div>
                    <div style={{ display: "flex" }}>
                      <Link
                        to={`/consultant-profile?user_id=${story.user_id._id}&profile_id=${story.profile_id}`}
                        style={{ color: "black" }}
                      >
                        <img
                          style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "50%",
                          }}
                          src={story.user_id.imageUrl}
                          alt="im-story"
                          onError={(e) => {
                            e.target.onError = null;
                            e.target.src = defualtImg;
                          }}
                        />{" "}
                      </Link>
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            fontWeight: "500",
                            color: "black",
                            marginLeft: "10px",
                          }}
                        >
                          {story.story_title}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "300",
                              color: "#737373",
                              marginLeft: "10px",
                              marginTop: "8px",
                            }}
                          >
                            {new Date(story.createdAt).toLocaleDateString(
                              "en-US",
                              dateoptions,
                            )}
                          </div>
                          <div>
                            {user.isLogin &&
                              user.userData.loginUser.is_consultant == "1" && (
                                <BsFillChatDotsFill
                                  size={20}
                                  className="cursor-pointer mr-3"
                                />
                              )}

                            {!user.isLogin && (
                              <div ref={LoginToSubRef} className="mb-2"></div>
                            )}
                            {isFollow && (
                              <button
                                className="btn btn-light rounded pl-2 pr-2 pt-1 pb-1"
                                style={{ background: "#dbdbdb" }}
                                onClick={(e) =>
                                  usubscribeGroup(story.user_id._id, e)
                                }
                              >
                                Subscribed
                              </button>
                            )}
                            {!isFollow && (
                              <button
                                className="btn btn-dark rounded pl-2 pr-2 pt-1 pb-1"
                                onClick={(e) =>
                                  buysubscribeGroup(story.user_id._id, e)
                                }
                              >
                                Subscribe
                              </button>
                            )}
                            {isFollow && (
                              <>
                                {!isNotifyMeAll && (
                                  <AiOutlineBell
                                    size={20}
                                    className="cursor-pointer ml-3 mr-1"
                                    onClick={() => notifyHandler(true)}
                                  />
                                )}
                                {isNotifyMeAll && (
                                  <HiBellAlert
                                    size={20}
                                    className="cursor-pointer ml-3 mr-1"
                                    onClick={() => notifyHandler(false)}
                                  />
                                )}
                              </>
                            )}

                            {showSignin && !user.isLogin && (
                              <div
                                style={{
                                  backgroundColor: "white",
                                  position: "absolute",
                                  zIndex: "1",
                                  padding: "20px",
                                  width: "230px",
                                }}
                              >
                                Sign in to subscribe this group
                                <hr />
                                <div
                                  style={{ cursor: "pointer", color: "red" }}
                                  onClick={() => {
                                    dispatch({ type: "OpenNavModel" });
                                  }}
                                >
                                  SIGN IN
                                </div>
                              </div>
                            )}
                            {showSignin && user.isLogin && (
                              <div
                                style={{
                                  backgroundColor: "white",
                                  position: "absolute",
                                  zIndex: "1",
                                  padding: "20px",
                                  width: "230px",
                                }}
                              >
                                Purchase the group to subscribe
                                <hr />
                                <div
                                  style={{ cursor: "pointer", color: "red" }}
                                  onClick={() => {
                                    setShowDummyCard(true);
                                  }}
                                >
                                  Purchase
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div
                  className={`${
                    story.story_language_id.code === "ar"
                      ? "text-right"
                      : "text-left"
                  } mt-3`}
                >
                  {parse(story.story_text)}
                </div>
              </div>
            )}
          </div>
          <div className="col-md-4" style={{ paddingLeft: "2px" }}>
            {CatStories.stories.getStoryModel.length > 0 &&
              CatStories.stories.getStoryModel.map((story, i) => {
                if (story._id !== params_id)
                  if (story.story_type == "text") {
                    return (
                      <Link
                        to={{
                          pathname: `/story/${story._id}`,
                          state: { from: "story" },
                        }}
                      >
                        <div
                          className="pb-3"
                          style={{ cursor: "pointer" }}
                          key={i}
                        >
                          <div
                            style={{
                              height: "108px",
                              width: "192px",
                              float: "left",
                              padding: "10px",
                              border: "2px solid #A7A5A6",
                            }}
                          >
                            {story.story_text.substring(0, 75) + "... "}
                            <span style={{ color: "#737373" }}>read more</span>
                          </div>
                          <div
                            style={{
                              float: "left",
                              marginLeft: "10px",
                            }}
                          >
                            <div className="text-title-expert">
                              {story.story_title}
                            </div>
                            <div style={{ color: "#737373" }}>
                              {story.user_id.user_name}
                            </div>
                            <div
                              style={{
                                color: "#737373",
                                fontSize: "14px",
                                fontWeight: "300",
                              }}
                            >
                              <span>{story.read.count} Views - </span>
                              <span>{story.daysAgo} day ago </span>
                            </div>
                          </div>
                          <div style={{ clear: "both" }}></div>
                        </div>
                      </Link>
                    );
                  } else if (story.story_type == "audio") {
                    return (
                      <Link
                        to={{
                          pathname: `/story/${story._id}`,
                          state: { from: "story" },
                        }}
                      >
                        <div
                          className="pb-3"
                          style={{ cursor: "pointer" }}
                          key={i}
                        >
                          <img
                            style={{
                              height: "108px",
                              objectFit: "cover",
                              width: "192px",
                              float: "left",
                              borderRadius: "10px",
                            }}
                            src={story.story_url}
                            alt="im-story"
                            onError={(e) => {
                              e.target.onError = null;
                              e.target.src = defualtImg;
                            }}
                          />
                          <div
                            style={{
                              float: "left",
                              marginLeft: "10px",
                            }}
                          >
                            <div className="text-title-expert">
                              {story.story_title}
                            </div>
                            <div style={{ color: "#737373" }}>
                              {story.user_id.user_name}
                            </div>
                            <div
                              style={{
                                color: "#737373",
                                fontSize: "14px",
                                fontWeight: "300",
                              }}
                            >
                              <span>{story.read.count} Views - </span>
                              <span>{story.daysAgo} day ago </span>
                            </div>
                          </div>
                          <div style={{ clear: "both" }}></div>
                        </div>
                      </Link>
                    );
                  } else if (story.story_type == "video_url") {
                    return (
                      <Link
                        to={{
                          pathname: `/story/${story._id}`,
                          state: { from: "story" },
                        }}
                      >
                        <div
                          className="pb-3"
                          style={{ cursor: "pointer" }}
                          key={i}
                        >
                          <img
                            style={{
                              height: "108px",
                              objectFit: "cover",
                              width: "192px",
                              float: "left",
                              borderRadius: "10px",
                            }}
                            src={`https://img.youtube.com/vi/${getId(
                              story.story_url,
                            )}/maxresdefault.jpg`}
                            alt="im-story"
                            onError={(e) => {
                              e.target.onError = null;
                              e.target.src = defualtImg;
                            }}
                          />
                          <div
                            style={{
                              float: "left",
                              marginLeft: "10px",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "sans-serif",
                                fontWeight: "500",
                                fontSize: "18px",
                                color: "black",
                              }}
                            >
                              {story.story_title}
                            </div>
                            <div style={{ color: "#737373" }}>
                              {story.user_id.user_name}
                            </div>
                            <div
                              style={{
                                color: "#737373",
                                fontSize: "14px",
                                fontWeight: "300",
                              }}
                            >
                              <span>{story.read.count} Views - </span>
                              <span>{story.daysAgo} day ago </span>
                            </div>
                          </div>
                          <div style={{ clear: "both" }}></div>
                        </div>
                      </Link>
                    );
                  } else if (story.story_type == "image") {
                    return (
                      <Link
                        to={{
                          pathname: `/story/${story._id}`,
                          state: { from: "story" },
                        }}
                      >
                        <div
                          className="pb-3 display-flex-block"
                          style={{ cursor: "pointer" }}
                          key={i}
                        >
                          <img
                            style={{
                              height: "108px",
                              objectFit: "cover",
                              width: "192px",
                              float: "left",
                              borderRadius: "10px",
                            }}
                            src={story.story_url}
                            alt="im-story"
                            onError={(e) => {
                              e.target.onError = null;
                              e.target.src = defualtImg;
                            }}
                          />
                          <div
                            style={{
                              float: "left",
                              marginLeft: "10px",
                            }}
                          >
                            <div className="text-title-expert">
                              {story.story_title}
                            </div>
                            <div style={{ color: "#737373" }}>
                              {story.user_id.user_name}
                            </div>
                            <div
                              style={{
                                color: "#737373",
                                fontSize: "14px",
                                fontWeight: "300",
                              }}
                            >
                              <span>{story.read.count} Views - </span>
                              <span>{story.daysAgo} day ago </span>
                            </div>
                          </div>
                          <div style={{ clear: "both" }}></div>
                        </div>
                      </Link>
                    );
                  } else if (story.story_type == "video") {
                    return (
                      <Link
                        to={{
                          pathname: `/story/${story._id}`,
                          state: { from: "story" },
                        }}
                      >
                        <div
                          className="pb-3 display-flex-block"
                          style={{ cursor: "pointer" }}
                          key={i}
                        >
                          <img
                            style={{
                              height: "108px",
                              objectFit: "cover",
                              width: "192px",
                              float: "left",
                              borderRadius: "10px",
                            }}
                            src={story.thumbnail_url}
                            alt="im-story"
                            onError={(e) => {
                              e.target.onError = null;
                              e.target.src = defualtImg;
                            }}
                          />

                          <div
                            style={{
                              float: "left",
                              marginLeft: "10px",
                            }}
                          >
                            <div className="text-title-expert">
                              {story.story_title}
                            </div>
                            <div style={{ color: "#737373" }}>
                              {story.user_id.user_name}
                            </div>
                            <div
                              style={{
                                color: "#737373",
                                fontSize: "14px",
                                fontWeight: "300",
                              }}
                            >
                              <span>{story.read.count} Views - </span>
                              <span>{story.daysAgo} day ago </span>
                            </div>
                          </div>
                          <div style={{ clear: "both" }}></div>
                        </div>
                      </Link>
                    );
                  }
              })}
            {loading && <LoadingCard count={3} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewStoryDesktop;
