import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios, { CancelToken, isCancel } from "axios";
import { useHistory } from "react-router-dom";
import {
  AiOutlineCaretDown,
  AiOutlineClose,
  AiFillCamera,
} from "react-icons/ai";

import profileDefualt from "../../images/default.jpeg";
import profilecoverDefualt from "../../images/profilecoverdefault.png";
import downarrow from "../../images/downarrow.png";
import Profiles from "./Profiles";
import ProfileStories from "./ProfileStories";
import { getDataPostWithHeader } from "../../functions/editProfile/editProfile";
import { dataURLtoBlob } from "../../utils/sharedfunction";
import Modal from "../../utils/models/Modal";
import ImageCrop from "../ImageCrop";
import sCover from "../../images/Scover.png";

const ProfileSection = ({
  setProfile,
  profile,
  setIsCourseListClose,
  menuType,
  setMenuType,
}) => {
  const {
    ProfileReducers,
    UserRequiredDropDown,
    BasicUserReducers,
    ChannelHandleReducers: { isShowProfile },
  } = useSelector((state) => state);

  const [showCrop, setShowCrop] = useState(false);
  const [showCoverCrop, setShowCoverCrop] = useState(false);
  const [videoProcessingUpload, setVideoProcessingUpload] = useState(false);
  const [imageProcessingUpload, setImageProcessingUpload] = useState(false);
  const [mageUploadPercentage, setImageUploadPercentage] = useState(0);
  const [videoUploadPercentage, setVideoUploadPercentage] = useState(0);
  const [Language, setLangauage] = useState("0");
  const [channelName, setChannelName] = useState("");
  const [createChannel, setCreateChannel] = useState(true);
  const [stafType, setStafType] = useState("");
  const [agreeNoNudityNoVoilanceNoDRUG, setAgreeNoNudityNoVoilanceNoDRUG] =
    useState(false);

  const channelNameRef = useRef(null);

  const dispatch = useDispatch({});
  const { profiles } = ProfileReducers;
  const { languages } = UserRequiredDropDown;
  const { user } = BasicUserReducers;
  const profileRefMedium = useRef(null);
  const coverRef = useRef(null);
  const aiProfileCameraRef = useRef(null);
  const aiCoverCameraRef = useRef(null);
  const cancelFileUpload = useRef(null);
  const history = useHistory();

  const setIsShowProfile = (isClose) => {
    dispatch({ type: "isShowProfile", payload: isClose });
  };

  // useEffect(() => {
  //   const getProfileUser = async () => {
  //     const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getProfilesOfUser`;
  //     const data = {
  //       user_id: user.loginUser._id,
  //     };
  //     const res = await getDataPostWithHeader(url, data, user.token);
  //     if (res.data.status === 200) {
  //       dispatch({
  //         type: "PROFILE",
  //         payload: res.data.body.profiles,
  //       });
  //     }
  //   };
  //   getProfileUser();
  // }, []);

  useEffect(() => {
    if (profiles.length > 0) handleCourses(menuType);
  }, [menuType]);

  useEffect(() => {
    if (history.location.state) {
      if (history.location.state.from === "addnewcourse") {
        handleCourses("COURSES");
      }
    }
  }, []);

  const crteateProfile = async () => {
    if (channelName !== "") {
      if (Language === "0") {
        alert("please select language");
        return;
      }

      if (!agreeNoNudityNoVoilanceNoDRUG) {
        alert(
          "You must agree to all terms (No Nudity, No Violence, No Weapons, No Drugs).",
        );

        return;
      }
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/createProfile`;
      const data = {
        user_id: user.loginUser._id,
        language_id: Language,
        profile_name: channelName,
        agreedNoNudityNoVoilanceNoDRUG: agreeNoNudityNoVoilanceNoDRUG,
      };
      try {
        let res = await getDataPostWithHeader(url, data, user.token);
        setIsShowProfile(true);
        setCreateChannel(false);
        dispatch({
          type: "PROFILE",
          payload: res.data.body,
        });
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    } else {
      alert("enter channel name");
    }
  };

  const openProfileHandler = () => {
    setShowCrop(true);
  };

  const imagefileInputHandler = (file) => {
    var imgeFile = dataURLtoBlob(file);
    profileRefMedium.current.src = file;
    var path = `${process.env.REACT_APP_BACKEND_URL}/api/users/updateProfileImage`;
    var type = "image";
    uploadProfileHandler(path, imgeFile, file);
  };

  const coverpictureInputHandler = (file, FileName) => {
    var imgeFile = dataURLtoBlob(file);
    coverRef.current.src = file;
    const data = {
      user_id: user.loginUser._id,
      keyname: "coverimages/userFiles/usercover",
      filename: FileName,
      fileType: imgeFile.type,
    };
    getPresignedUrl("getPresignedURLForCoverUpload", data, imgeFile, "image");
  };

  const getPresignedUrl = async (apiName, data, pickedFile, type) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/${apiName}`;
      const res = await getDataPostWithHeader(url, data, user.token);
      if (res.data.status === 200) {
        uploadToS3(
          pickedFile,
          res.data.body.preSignedurl,
          apiName,
          res.data.body.fileUrl,
        );
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const uploadToS3 = (pickedFile, preSignedurl, apiName, fileUrl) => {
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          if (percent === 100) {
            if (apiName === "getPresignedURLForCoverUpload") {
              setImageProcessingUpload(true);
            } else {
              setVideoProcessingUpload(true);
            }
          } else {
            if (apiName === "getPresignedURLForCoverUpload") {
              setImageUploadPercentage(percent);
            } else {
              console.log("chat video percent", percent);
              setVideoUploadPercentage(percent);
            }
          }
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel),
      ),
    };

    axios
      .put(preSignedurl, pickedFile, options)
      .then(async (res) => {
        if (res.status === 200) {
          if (apiName === "getPresignedURLForCoverUpload") {
            setImageUploadPercentage(0);
            setImageProcessingUpload(false);

            const data = {
              user_id: user.loginUser._id,
              cover_url: fileUrl,
            };

            const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/updateProfileCover`;
            const rescover = await getDataPostWithHeader(url, data, user.token);

            dispatch({
              type: "profileCover",
              payload: rescover.data.body.cover,
            });
          } else {
            setVideoUploadPercentage(0);
            setVideoProcessingUpload(false);
          }
        }
      })
      .catch((err) => {
        if (apiName === "getPresignedURLForCoverUpload") {
          setImageUploadPercentage(0);
          setImageProcessingUpload(false);
        } else {
          setVideoUploadPercentage(0);
          setVideoProcessingUpload(false);
        }
      });
  };

  const uploadProfileHandler = (path, file, base64) => {
    let formData = new FormData();

    formData.append("image", file);
    formData.append("user_id", user.loginUser._id);
    formData.append("Name", user.loginUser.imageUrl);
    const options = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          //setUploadPercentage(percent)
        }
      },
    };

    axios
      .post(path, formData, options)
      .then((res) => {
        //setUploadPercentage(0)

        dispatch({
          type: "profileImage",
          payload: res.data.body.imageUrl,
        });
        //document.getElementById('profile_pic_small').src = base64
      })
      .catch((err) => {
        console.log(err);
        alert("something went wrong");
        if (err.response) {
          if (err.response.status === 401) {
            localStorage.removeItem("userDataSessionway");
            window.location.replace("/");
          }
        }
        toast.error(err.message);
        //profileRef.current.src = ''
        //setUploadPercentage(0)
        if (isCancel(err)) {
          toast.success(err.message);
          alert(err.message);
        }
      });
  };

  const handleCourses = (menutype, staftype) => {
    setMenuType(menutype);

    if (staftype) {
      setStafType(staftype);
    }
    document.querySelectorAll(".profile-bio-tab-2").forEach((div) => {
      div.style.borderBottom = "none";
      div.style.color = "#5e5e5e";
    });

    document.getElementById(
      `lang_list_profile_parent_tab_${menutype}`,
    ).style.borderBottom = "solid 4px #0c9fd3";

    document.getElementById(
      `lang_list_profile_parent_tab_${menutype}`,
    ).style.color = "black";
  };

  const closeProfileHandler = () => setShowCrop(false);
  return (
    <>
      <Modal
        show={showCrop}
        onCancel={closeProfileHandler}
        headerClass="text-center"
        containerStyle={{ width: "500px" }}
      >
        <div className="map-container">
          <ImageCrop
            close={closeProfileHandler}
            onSave={imagefileInputHandler}
            aspectRatio={1 / 1}
            profile={true}
            cropSize={{ width: 300, height: 300 }}
            objectFit="vertical-cover"
          />
        </div>
      </Modal>

      <Modal
        show={showCoverCrop}
        onCancel={() => {
          setShowCoverCrop(false);
        }}
        headerClass="text-center"
        containerStyle={{ width: "700px" }}
      >
        <div className="map-container">
          <ImageCrop
            close={() => {
              setShowCoverCrop(false);
            }}
            onSave={coverpictureInputHandler}
            aspectRatio={6 / 1}
            ratio="6:1"
            objectFit="horizontal-cover"
            pictureStandard="Cover picture Sessionway*(1500px width * 250px height)"
          />
        </div>
      </Modal>
      <div className="col-md-12 profile-bg-section">
        <div>
          <div id="session_short_intro">
            <div>
              <div
                style={{
                  cursor: "pointer",
                }}
                className="ups-bg"
              >
                <div
                  style={{ position: "relative" }}
                  onMouseOver={() => {
                    aiCoverCameraRef.current.style.display = "flex";
                  }}
                  onMouseLeave={() => {
                    aiCoverCameraRef.current.style.display = "none";
                  }}
                >
                  <img
                    src={user.loginUser.cover}
                    style={{
                      width: "100%",
                      aspectRatio: "6/1",
                    }}
                    className="ups-bg-img"
                    ref={coverRef}
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = profilecoverDefualt;
                    }}
                  />
                  <div
                    className="ups-bg-upload-btn"
                    ref={aiCoverCameraRef}
                    style={{ display: "none" }}
                  >
                    <AiFillCamera
                      size="2.5rem"
                      style={{ marginTop: "-2px", marginLeft: "0.5px" }}
                      onClick={() => setShowCoverCrop(true)}
                    />
                  </div>
                </div>
                <div className="ups-profile">
                  <div
                    className="ups-profile-area"
                    onMouseOver={() => {
                      aiProfileCameraRef.current.style.display = "flex";
                    }}
                    onMouseLeave={() => {
                      aiProfileCameraRef.current.style.display = "none";
                    }}
                  >
                    <img
                      src={user.loginUser.imageUrl}
                      ref={profileRefMedium}
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = profileDefualt;
                      }}
                      className="ups-profile-img"
                      alt="group-profile"
                    />
                    <div
                      className="ups-profile-upload-btn"
                      ref={aiProfileCameraRef}
                      style={{ display: "none" }}
                    >
                      <AiFillCamera
                        className="ups-size-camera-upload"
                        style={{ marginTop: "-5px" }}
                        onClick={openProfileHandler}
                      />
                    </div>
                  </div>
                  <div className="ups-profile-text">
                    <div className="ups-name"> {user.loginUser.user_name}</div>
                    <div className="ups-subscribe">
                      {profiles.length > 0 && (
                        <>
                          <div className="ups-subscribe-count">
                            {user.Followers ? user.Followers : "NO"}
                          </div>
                          <div className="ups-subscribe-text">Subscribers</div>
                        </>
                      )}
                      {/* {profiles.length <= 0 && (
                        <div
                          style={{ cursor: "pointer" }}
                          className="ups-subscribe-text"
                          onClick={() => setCreateChannel(true)}
                        >
                          Create Channel
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div
              className='cursor-pointer push-right'
              style={{ color: 'rgba(0, 0, 0, 0.65)' }}
              onClick={() => {
                setIsShowProfile(!isShowProfile)
                dispatch({ type: 'isCourseListClose', payload: true })
              }}
            >
              {isShowProfile ? (
                <div>
                  Hide Channles <AiOutlineCaretUp size={20} />
                </div>
              ) : (
                <div>
                  Show Channels <AiOutlineCaretDown size={20} />
                </div>
              )}
            </div> */}
            <div style={{ clear: "both" }}></div>
          </div>
        </div>
      </div>
      <div className="col-md-12 profile-tab-section">
        {profiles.length <= 0 && createChannel && (
          <>
            <div className="border-bottom-white2 ">
              <div className="profilemenu-scroll">
                <div
                  id={`lang_list_profile_parent_tab_channel_create`}
                  className={`profile-bio-tab-2 profilemenulink ml-2 cursor-pointer`}
                  style={{ color: "black", borderBottom: "solid 4px #4d4341" }}
                >
                  Home
                  {/* {menuType === "STORIES" && profiles.length > 1 && (
                    <span>:-</span>
                  )} */}
                </div>

                <div
                  id={`lang_list_profile_parent_tab_STAFS`}
                  className="profile-bio-tab-2 profilemenulink ml-2 cursor-pointer"
                  style={{
                    borderBottom: "none",
                  }}
                >
                  EXPERT CV
                </div>

                <div
                  id={`lang_list_profile_parent_tab_ARTICLES`}
                  className="profile-bio-tab-2 profilemenulink ml-2 cursor-pointer"
                  style={{
                    borderBottom: "none",
                  }}
                >
                  ARTICLES
                </div>

                <div
                  id={`lang_list_profile_parent_tab_SCIRES`}
                  className="profile-bio-tab-2 profilemenulink ml-2 cursor-pointer"
                  style={{
                    borderBottom: "none",
                  }}
                >
                  SCIENCE & RESEARCH
                </div>

                <div
                  id={`lang_list_profile_parent_tab_PGROUPS`}
                  className="profile-bio-tab-2 profilemenulink ml-2 cursor-pointer"
                  style={{
                    borderBottom: "none",
                  }}
                >
                  PRIVATE GROUPS
                </div>

                <div
                  id={`lang_list_profile_parent_tab_COURSES`}
                  className="profile-bio-tab-2 profilemenulink ml-2 cursor-pointer"
                  style={{
                    borderBottom: "none",
                  }}
                >
                  TRAINING COURSES
                </div>

                <div
                  id={`lang_list_profile_parent_tab_SUBSCRIBERS`}
                  className="profile-bio-tab-2 profilemenulink ml-2 cursor-pointer"
                  style={{
                    borderBottom: "none",
                  }}
                >
                  SUBSCRIBERS
                </div>

                <div
                  id={`lang_list_profile_parent_tab_FINANCE`}
                  className="profile-bio-tab-2 profilemenulink ml-2 cursor-pointer"
                  style={{
                    borderBottom: "none",
                  }}
                >
                  FINANCE
                </div>

                <div
                  id={`lang_list_profile_parent_tab_LOCATIONS`}
                  className="profile-bio-tab-2 profilemenulink ml-2 cursor-pointer"
                  style={{
                    borderBottom: "none",
                  }}
                >
                  CONTACT
                </div>

                <div className="clearfix"></div>
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="col-md-6 com-sm-12 mt-3">
              <div>
                <div style={{ width: "200px", float: "left" }}>
                  <select
                    onChange={(e) => {
                      setLangauage(e.target.value);
                      channelNameRef.current.focus();
                    }}
                    style={{
                      borderBottom: "solid 1px #118acf",
                      height: "30px",
                    }}
                    className="form-control registration-from-control-border"
                  >
                    <option value="0">Add Langauge</option>
                    {languages.length > 0 &&
                      languages.map((lang) => {
                        return (
                          <option key={lang._id} value={lang._id}>
                            {lang.name}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <div style={{ float: "right" }}>
                  <>
                    <AiOutlineClose
                      size={20}
                      onClick={() => {
                        setLangauage("0");
                        setChannelName("");
                        setAgreeNoNudityNoVoilanceNoDRUG(false);
                      }}
                      className="cursor-pointer"
                    />
                  </>
                </div>
                <div className="clearfix"></div>
              </div>
              <hr />
              <div>
                <input
                  type="text"
                  className="new-profile-name-title mb-3"
                  placeholder="Enter channel name"
                  style={{ background: "white" }}
                  value={channelName}
                  ref={channelNameRef}
                  onChange={(e) => setChannelName(e.target.value)}
                  // onBlur={(e) => {
                  //   crteateProfile();
                  // }}
                />
                <div
                  className="display-flex"
                  style={{ justifyContent: "flex-end" }}
                >
                  <div className="agreement-text">
                    <div className="mb-3 display-flex">
                      <input
                        type="checkbox"
                        name="NOVOILANCE"
                        id="NOVOILANCE"
                        checked={agreeNoNudityNoVoilanceNoDRUG} // State variable representing the checked state
                        onChange={(e) =>
                          setAgreeNoNudityNoVoilanceNoDRUG(e.target.checked)
                        } // Update the state
                      />

                      <label for="NOVOILANCE" className="ml-3">
                        I agree I Don't create contents on (Nudity, Violence,
                        Weapons, Drugs)
                      </label>
                    </div>
                    {/* <div className="mb-3">
                      <input
                        type="checkbox"
                        className="mb-3"
                        name="NOWEAPON"
                        id="NOWEAPON"
                        onChange={(e) => setAgreeNoWeapon(e.target.checked)}
                      />
                      <label for="NOWEAPON" className="ml-3">
                        I agree i will not create content on weapon
                      </label>
                    </div>
                    <div className="mb-3">
                      <input
                        type="checkbox"
                        className="mb-3"
                        name="NONUDITY"
                        id="NONUDITY"
                        onChange={(e) => setAgreeNoNudity(e.target.checked)}
                      />
                      <label for="NONUDITY" className="ml-3">
                        I agree i will not create content on weapon
                      </label>
                    </div>

                    <div className="mb-3">
                      <input
                        type="checkbox"
                        className="mb-3"
                        name="NODRUG"
                        id="NODRUG"
                        onChange={(e) => setAgreeNoDRUG(e.target.checked)}
                      />
                      <label for="NODRUG" className="ml-3">
                        I agree i will not create content on drugs
                      </label>
                    </div> */}
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <button
                        className="btn btn-primary"
                        onClick={crteateProfile}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {profiles.length > 0 && (
          <div>
            <div className="border-bottom-white2 ">
              <div className="profilemenu-scroll">
                <div
                  id={`lang_list_profile_parent_tab_HOME`}
                  className={`profile-bio-tab-2 profilemenulink ml-2 cursor-pointer`}
                  style={{ color: "black", borderBottom: "solid 4px #0c9fd3" }}
                  onClick={() => {
                    handleCourses("HOME");
                  }}
                >
                  HOME
                  {/* {menuType === "STORIES" && profiles.length > 1 && (
                    <span>:-</span>
                  )} */}
                </div>
                <div
                  id={`lang_list_profile_parent_tab_STORIES`}
                  className={`profile-bio-tab-2 profilemenulink ml-2 cursor-pointer`}
                  style={{ color: "black" }}
                  onClick={() => {
                    handleCourses("STORIES", "STORIES");
                  }}
                >
                  STORIES{" "}
                  {/* {menuType === "STORIES" && profiles.length > 1 && (
                    <span>:-</span>
                  )} */}
                </div>

                {/* {user.loginUser.accounttype !== "2" && (
                  <div
                    id={`lang_list_profile_parent_tab_STAFS`}
                    className="profile-bio-tab-2 profilemenulink ml-2 cursor-pointer"
                    style={{
                      borderBottom: "none",
                    }}
                    onClick={() => {
                      handleCourses("STAFS", "CV");
                    }}
                  >
                    CV
                  </div>
                )} */}

                <div
                  id={`lang_list_profile_parent_tab_STAFS`}
                  className="profile-bio-tab-2 profilemenulink ml-2 cursor-pointer"
                  style={{
                    borderBottom: "none",
                  }}
                  onClick={() => {
                    handleCourses("STAFS", "TEAM");
                  }}
                >
                  EXPERT CV
                </div>

                <div
                  id={`lang_list_profile_parent_tab_ARTICLES`}
                  className="profile-bio-tab-2 profilemenulink ml-2 cursor-pointer"
                  style={{
                    borderBottom: "none",
                  }}
                  onClick={() => {
                    handleCourses("ARTICLES");
                  }}
                >
                  ARTICLES
                </div>

                <div
                  id={`lang_list_profile_parent_tab_SCIRES`}
                  className="profile-bio-tab-2 profilemenulink ml-2 cursor-pointer"
                  style={{
                    borderBottom: "none",
                  }}
                  onClick={() => {
                    handleCourses("SCIRES");
                  }}
                >
                  SCIENCE & RESEARCH
                </div>

                <div
                  id={`lang_list_profile_parent_tab_PGROUPS`}
                  className="profile-bio-tab-2 profilemenulink ml-2 cursor-pointer"
                  style={{
                    borderBottom: "none",
                  }}
                  onClick={() => {
                    handleCourses("PGROUPS");
                  }}
                >
                  PRIVATE GROUPS
                </div>

                <div
                  id={`lang_list_profile_parent_tab_COURSES`}
                  className="profile-bio-tab-2 profilemenulink ml-2 cursor-pointer"
                  style={{
                    borderBottom: "none",
                  }}
                  onClick={() => {
                    handleCourses("COURSES");
                  }}
                >
                  TRAINING COURSES
                </div>

                <div
                  id={`lang_list_profile_parent_tab_SUBSCRIBERS`}
                  className="profile-bio-tab-2 profilemenulink ml-2 cursor-pointer"
                  style={{
                    borderBottom: "none",
                  }}
                  onClick={() => {
                    handleCourses("SUBSCRIBERS");
                  }}
                >
                  SUBSCRIBERS
                </div>

                <div
                  id={`lang_list_profile_parent_tab_FINANCE`}
                  className="profile-bio-tab-2 profilemenulink ml-2 cursor-pointer"
                  style={{
                    borderBottom: "none",
                  }}
                  onClick={() => {
                    handleCourses("FINANCE");
                  }}
                >
                  FINANCE
                </div>

                <div
                  id={`lang_list_profile_parent_tab_LOCATIONS`}
                  className="profile-bio-tab-2 profilemenulink ml-2 cursor-pointer"
                  style={{
                    borderBottom: "none",
                  }}
                  onClick={() => {
                    handleCourses("LOCATIONS");
                  }}
                >
                  CONTACT
                </div>

                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        )}
        <ProfileStories
          menuType={menuType}
          setProfile={setProfile}
          setMenuType={setMenuType}
          user={user}
          stafType={stafType}
        />
      </div>
    </>
  );
};

export default ProfileSection;
