import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import defualtImg from "../../images/Read&ShareThumbnail.png";
import ReactPlayer from "react-player";
import profileDefualt from "../../images/default.jpeg";
import Audio from "../../images/Audio.jpeg";
import { MdOutlinePlayCircleFilled } from "react-icons/md";
import { BsPlayFill } from "react-icons/bs";
import "./storyCard.css";
import { getRequestedData } from "../../functions/editProfile/editProfile";
import playIcon from "../../images/play-button-svgrepo-com.svg";

const weburl = process.env.REACT_APP_BACKEND_URL;

const ConsultantStoryCard = ({
  story,
  isLogin,
  userid,
  backgroundColor = "#fff",
}) => {
  const [youtubeVideoId, setYoutubeVideoID] = useState({});
  const [youtubeThumbnail, setYoutubeThumbnail] = useState("");
  const [isFromMobile, setIsFromMobile] = useState(true);

  useEffect(() => {
    function checkDevice() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        setIsFromMobile(true);
      } else {
        setIsFromMobile(false);
      }
    }

    checkDevice();
  }, []);

  // useEffect(() => {
  //   if (story.story_type === "video_url") {
  //     const videoId = getId(story.story_url);
  //     setYoutubeVideoID(videoId);
  //     getYoutbe(videoId);
  //   }
  // }, [story.story_type]);

  const getYoutbe = async (videoId) => {
    let res = await getRequestedData(
      `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`,
    );
    setYoutubeThumbnail(res.data.thumbnail_url);
  };

  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  return (
    <>
      {story.story_type == "image" && (
        <div className="card-box story-card-box story-backgroud-color">
          <Link to={`/expert/${story._id}?type=image`}>
            {story.thumbnail_url && (
              <div
                className="image-warapper-ratio-adujestment-user-story"
                style={{ backgroundColor: "black", borderRadius: "5px" }}
              >
                <img
                  className="image-whereplayer-shows-user-story"
                  style={{
                    height: "100%",
                  }}
                  src={story.thumbnail_url}
                  alt="im-story"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = defualtImg;
                  }}
                />
              </div>
            )}
          </Link>

          <div style={{ display: "flex" }} className="story-card-box-profile">
            <Link to={`/expert/${story._id}?type=image`}>
              <div style={{ marginLeft: "8px" }}>
                <div className="titile-card">{story.story_title}</div>
              </div>
            </Link>
          </div>
          {/* <Link to={`/expert/${story._id}?type=image`}>
            {!story.thumbnail_url && (
              <div
                className={`${
                  story.language.code === "ar" ? "text-right" : "text-left"
                } ml-2`}
              >
                {story.story_text &&
                  story.story_text.length <= 350 &&
                  parse(story.story_text)}
                {story.story_text &&
                  story.story_text.length > 350 &&
                  parse(story.story_text.substring(0, 350))}
              </div>
            )}
            {story.thumbnail_url && (
              <div
                className={`${
                  story.language.code === "ar" ? "text-right" : "text-left"
                } description-box ml-2 mt-2`}
              >
                {story.story_text &&
                  story.story_text.length <= 50 &&
                  parse(story.story_text)}
                {story.story_text &&
                  story.story_text.length > 50 &&
                  parse(story.story_text.substring(0, 200))}
              </div>
            )}
          </Link> */}
        </div>
      )}
      {story.story_type == "video" && (
        <div className="card-box story-card-box story-backgroud-color">
          <Link to={`/story/${story._id}`} className="mobile-card-anchor">
            <div
              className="image-warapper-ratio-adujestment-user-story"
              style={{ backgroundColor: "black", borderRadius: "5px" }}
            >
              <img
                className="image-whereplayer-shows-user-story "
                style={{
                  height: "100%",
                  objectFit: "cover",
                }}
                src={story.thumbnail_url}
                alt="im-story"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = defualtImg;
                }}
              />
              <div className="play-video-home-page">
                <div
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "50%",
                    background: "black",
                    opacity: "0.6",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BsPlayFill
                    style={{ color: "white", marginLeft: "3px" }}
                    size={30}
                  />
                </div>
              </div>
            </div>

            {/* <div className="video-warapper-ratio-adujestment d-block d-sm-none">
              <video
                autoPlay
                muted
                loop
                playsInline
                preload="metadata"
                className="video-whereplayer-shows-mobile-full-width"
                onEnded={() => {
                  console.log("ended");
                }}
              >
                <source
                  src={story.story_url_quality_versions.url_360}
                  type="video/mp4"
                />
              </video>
            </div> */}
          </Link>
          <div style={{ display: "flex" }} className="story-card-box-profile">
            <Link to={`/story/${story._id}`}>
              <div style={{ marginLeft: "8px" }}>
                <div className="titile-card">{story.story_title}</div>
              </div>
            </Link>
          </div>
          {/* <Link to={`/story/${story._id}`}>
            <div
              className={`${
                story.language.code === "ar" ? "text-right" : "text-left"
              } description-box ml-2 mt-2`}
            >
              {story.story_text &&
                story.story_text.length <= 50 &&
                parse(story.story_text)}
              {story.story_text &&
                story.story_text.length > 50 &&
                parse(story.story_text.substring(0, 100))}
            </div>
          </Link> */}
        </div>
      )}
      {story.story_type == "stafs" && (
        <div className="card-box story-card-box story-backgroud-color">
          <Link to={`/expert/${story._id}?type=stafs`}>
            {story.thumbnail_url && (
              <div
                className="image-warapper-ratio-adujestment-user-story"
                style={{ backgroundColor: "black", borderRadius: "5px" }}
              >
                <img
                  className="image-whereplayer-shows-user-story"
                  style={{
                    height: "100%",
                    objectFit: "cover",
                    width: "100%",
                  }}
                  src={story.thumbnail_url}
                  alt="im-story"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = defualtImg;
                  }}
                />
              </div>
            )}
          </Link>

          <div style={{ display: "flex" }} className="story-card-box-profile">
            <Link to={`/expert/${story._id}?type=stafs`}>
              <div style={{ marginLeft: "8px" }}>
                <div className="titile-card">{story.story_title}</div>
              </div>
            </Link>
          </div>
          {/* <Link to={`/expert/${story._id}?type=stafs`}>
            {!story.thumbnail_url && (
              <div
                className={`${
                  story.language.code === "ar" ? "text-right" : "text-left"
                } ml-2`}
              >
                {story.story_text &&
                  story.story_text.length <= 350 &&
                  parse(story.story_text)}
                {story.story_text &&
                  story.story_text.length > 350 &&
                  parse(story.story_text.substring(0, 350))}
              </div>
            )}
            {story.thumbnail_url && (
              <div
                className={`${
                  story.language.code === "ar" ? "text-right" : "text-left"
                } description-box ml-2 mt-2`}
              >
                {story.story_text &&
                  story.story_text.length <= 50 &&
                  parse(story.story_text)}
                {story.story_text &&
                  story.story_text.length > 50 &&
                  parse(story.story_text.substring(0, 200))}
              </div>
            )}
          </Link> */}
        </div>
      )}

      {/* {story.story_type === "text" && (
        <div className="card-box" style={{background:" #f2f0f0"}} style={{ background: `${backgroundColor}` }}>
          <Link to={`/story/${story._id}`} className="mobile-card-anchor">
            {story.thumbnail_url && (
              <div
                className="image-warapper-ratio-adujestment-user-story"
                style={{ backgroundColor: "black",}}
              >
                <img
                  className="image-whereplayer-shows-user-story"
                  style={{
                    height: "100%",
                    objectFit: "cover",
                    width: "100%",
                  }}
                  src={story.thumbnail_url}
                  alt="im-story"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = defualtImg;
                  }}
                />
              </div>
            )}
          </Link>
          <div style={{ display: "flex" }} className="mobile-card-cover">
            {userid === story.user_id._id ? (
              <Link to={`/profile`}>
                <img
                  style={{
                    width: "36px",
                    height: "36px",
                    borderRadius: "50%",
                    float: "left",
                    marginTop: "12px",
                  }}
                  src={story.user_id.imageUrl}
                  alt="im-story-profile"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = profileDefualt;
                  }}
                />
              </Link>
            ) : (
              <Link
                to={`/consultant-profile?user_id=${story.user_id._id}&profile_id=${story.profiles._id}`}
              >
                <img
                  style={{
                    width: "36px",
                    height: "36px",
                    borderRadius: "50%",
                    float: "left",
                    marginTop: "12px",
                  }}
                  src={story.user_id.imageUrl}
                  alt="im-story-profile"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = profileDefualt;
                  }}
                />
              </Link>
            )}

            <Link to={`/story/${story._id}`}>
              <div style={{ marginLeft: "8px" }}>
                <div className="titile-card">{story.story_title}</div>
              </div>
            </Link>
          </div>
          <Link to={`/story/${story._id}`} className="mobile-card-desc">
            {!story.thumbnail_url && (
              <div
                className={`${
                  story.language.code === "ar" ? "text-right" : "text-left"
                } description-box-with-no-image ml-2 mt-2`}
              >
                {story.story_text &&
                  story.story_text.length <= 350 &&
                  parse(story.story_text)}
                {story.story_text &&
                  story.story_text.length > 350 &&
                  parse(story.story_text.substring(0, 350))}
              </div>
            )}
            {story.thumbnail_url && (
              <div
                className={`${
                  story.language.code === "ar" ? "text-right" : "text-left"
                } description-box ml-2 mt-2`}
              >
                {story.story_text &&
                  story.story_text.length <= 50 &&
                  story.story_text}
                {story.story_text &&
                  story.story_text.length > 50 &&
                  story.story_text.substring(0, 200)}
              </div>
            )}
          </Link>
        </div>
      )} */}

      {/* {story.story_type == "audio" && (
        <div className="card-box" style={{background:" #f2f0f0"}}>
          <Link to={`/story/${story._id}`} className="mobile-card-anchor">
            <div
              className="image-warapper-ratio-adujestment-user-story"
              style={{ backgroundColor: "black",}}
            >
              <img
                className="image-whereplayer-shows-user-story"
                style={{
                  height: "100%",
                  objectFit: "cover",
                  width: "100%",
                }}
                src={story.thumbnail_url}
                alt="im-story"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = Audio;
                }}
              />
            </div>
          </Link>
          <div style={{ display: "flex" }} className="mobile-card-cover">
            {userid === story.user_id._id ? (
              <Link to={`/profile`}>
                <img
                  style={{
                    width: "36px",
                    height: "36px",
                    borderRadius: "50%",
                    float: "left",
                    marginTop: "12px",
                  }}
                  src={story.user_id.imageUrl}
                  alt="im-story-profile"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = profileDefualt;
                  }}
                />
              </Link>
            ) : (
              <Link
                to={`/consultant-profile?user_id=${story.user_id._id}&profile_id=${story.profiles._id}`}
              >
                <img
                  style={{
                    width: "36px",
                    height: "36px",
                    borderRadius: "50%",
                    float: "left",
                    marginTop: "12px",
                  }}
                  src={story.user_id.imageUrl}
                  alt="im-story-profile"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = profileDefualt;
                  }}
                />
              </Link>
            )}
            <Link to={`/story/${story._id}`}>
              <div style={{ marginLeft: "8px" }}>
                <div className="titile-card">{story.story_title}</div>
              </div>
            </Link>
          </div>
          <Link to={`/story/${story._id}`} className="mobile-card-desc">
            <div
              className={`${
                story.language.code === "ar" ? "text-right" : "text-left"
              } description-box ml-2 mt-2`}
            >
              {story.story_text &&
                story.story_text.length <= 350 &&
                parse(story.story_text)}
              {story.story_text &&
                story.story_text.length > 50 &&
                parse(story.story_text.substring(0, 200))}
            </div>
          </Link>
        </div>
      )} */}

      {/* {story.story_type == "video_url" && (
        <div className="card-box" style={{background:" #f2f0f0"}}>
          <Link to={`/story/${story._id}`} className="mobile-card-anchor">
            {story.thumbnail_url ? (
              <img
                style={{
                  height: "100%",
                  objectFit: "cover",
                  width: "100%",
                }}
                src={story.thumbnail_url}
                alt="im-story"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = Audio;
                }}
              />
            ) : (
              <img
                style={{
                  height: "100%",
                  objectFit: "cover",
                  width: "100%",
                }}
                src={`https://img.youtube.com/vi/${youtubeVideoId}/maxresdefault.jpg`}
                alt="im-story"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = `${youtubeThumbnail}`;
                }}
              />
            )}
          </Link>

          <div style={{ display: "flex" }} className="mobile-card-cover">
            {userid === story.user_id._id ? (
              <Link to={`/profile`}>
                <img
                  style={{
                    width: "36px",
                    height: "36px",
                    borderRadius: "50%",
                    float: "left",
                    marginTop: "12px",
                  }}
                  src={story.user_id.imageUrl}
                  alt="im-story-profile"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = profileDefualt;
                  }}
                />
              </Link>
            ) : (
              <Link
                to={`/consultant-profile?user_id=${story.user_id._id}&profile_id=${story.profiles._id}`}
              >
                <img
                  style={{
                    width: "36px",
                    height: "36px",
                    borderRadius: "50%",
                    float: "left",
                    marginTop: "12px",
                  }}
                  src={story.user_id.imageUrl}
                  alt="im-story-profile"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = profileDefualt;
                  }}
                />
              </Link>
            )}
            <Link to={`/story/${story._id}`}>
              <div style={{ marginLeft: "8px" }}>
                <div className="titile-card">{story.story_title}</div>
              </div>
            </Link>
          </div>
          <Link to={`/story/${story._id}`} className="mobile-card-desc">
            <div
              className={`${
                story.language.code === "ar" ? "text-right" : "text-left"
              } description-box ml-2 mt-2`}
            >
              {story.story_text &&
                story.story_text.length <= 350 &&
                parse(story.story_text)}
              {story.story_text &&
                story.story_text.length > 50 &&
                parse(story.story_text.substring(0, 200))}
            </div>
          </Link>
        </div>
      )} */}
    </>
  );
};

export default ConsultantStoryCard;
