import React, { useRef, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Input } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import EditProfile from "../Components/navbar/EditProfile";
import DropDownMenu from "../Components/home/DropDownMenu";

import {
  AiOutlineClose,
  AiTwotoneLock,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import {
  BsFillPersonFill,
  BsThreeDotsVertical,
  BsSearch,
  BsHeart,
} from "react-icons/bs";
import { HiOutlineUserCircle } from "react-icons/hi";

import profileDefualt from "../images/default.jpeg";

import Logo from "../images/sessionway.svg";

import {
  getDataPostWithHeader,
  getDataPostWithOutHeader,
  getRequestedData,
  getRequestedDataWithHeader,
} from "../functions/editProfile/editProfile";
//css
import "../navbar/navbar.css";

const { Search } = Input;

const ReelNavbar = ({
  isNavModalOpen,
  formLoginPage,
  isLogin,
  dispatch,
  profileImage,
  token,
  userId,
  homeNavbarcollapse,
  setHomeNavbarcollapse,
  chat,
  isSearchBar,
  className,
  cart,
  Wishlist,
  target,
  redirect,
  searchUsers,
  setDarkBackgroundToRead,
  darkBackgroundToRead,
  setSearchStories,
  setLoadingSearchUser,
}) => {
  const userName = useRef(null);
  const password = useRef(null);
  const errorDetails = useRef(null);
  const progressBarRef = useRef(null);
  const navbarMenuRef = useRef(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const [searshBar, setSearshBar] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [isKeepLogin, setIsKeepLogin] = useState(true);

  const history = useHistory();

  useEffect(() => {
    window.addEventListener("resize", handleresizListner);
    return () => {
      window.removeEventListener("resize", handleresizListner);
    };
  });

  const handleresizListner = () => {
    if (window.outerWidth <= 1000) {
      setHomeNavbarcollapse(true);
    }
  };

  useEffect(() => {
    if (searchUsers.search.clear) {
      setSearchValue("");
    }
  }, [searchUsers.search.clear]);

  console.log(searchUsers);

  useEffect(() => {
    if (isLogin) {
      getWishlist();
      getCarts();
    }
  }, [isLogin]);

  const getWishlist = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getWishlists?user_id=${userId}`;
    try {
      let res = await getRequestedDataWithHeader(url, token);

      dispatch({ type: "WISHLIST", payload: res.data.body.length });
    } catch (err) {
      console.log(err);
      //alert(err.message);
    }
  };

  const getCarts = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getCart?user_id=${userId}`;
    try {
      let res = await getRequestedDataWithHeader(url, token);
      console.log("zoom", res.data.body.courses.length);
      dispatch({ type: "CARTTOTAL", payload: res.data.body.courses.length });
      dispatch({
        type: "CARTCHECKOUT",
        payload: {
          courses: res.data.body.courses,
          cartTotalPrice: res.data.body.priceTotol,
        },
      });
    } catch (err) {
      alert(err.message);
    }
  };

  const chatNotificationHandler = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/chat/messageNotification?reciverId=${userId}`;

    try {
      let res = await getRequestedDataWithHeader(url, token);
    } catch (err) {
      console.log(err.message);
    }
  };

  // const [login, setLogin] = useState(false)

  const logoutHandler = async () => {
    const url = process.env.REACT_APP_BACKEND_URL;
    navbarMenuRef.current.style.display = "none";
    try {
      const response = await fetch(`${url}/api/users/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          user_id: userId,
        }),
      });
      const responseData = await response.json();
      if (!response.ok) {
        if (responseData.status === 401) {
          localStorage.removeItem("userDataSessionWay");
          window.location.replace("/");
        } else {
          throw new Error(responseData.message || "something went wrong");
        }
      } else {
        localStorage.removeItem("userDataSessionWay");
        window.location.replace("/");
      }
    } catch {}
  };

  const loginSubmit = async () => {
    const url = process.env.REACT_APP_BACKEND_URL;

    if (userName.current.value && password.current.value) {
      try {
        progressBarRef.current.style.display = "block";
        const response = await fetch(`${url}/api/users/userLogin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            login_user_id: userName.current.value,
            password: password.current.value,
            isKeepLogin: isKeepLogin,
          }),
        });
        const responseData = await response.json();

        if (!response.ok) {
          const error = responseData.message.split(":")[1].trim();
          if (error.localeCompare("password mismatch") === 0) {
            errorDetails.current.innerHTML = "Incorrect Password";
            errorDetails.current.style.color = "red";
            progressBarRef.current.style.display = "none";
          } else if (
            error.localeCompare("User not Exsited Try with other user_id") === 0
          ) {
            errorDetails.current.innerHTML = error;
            errorDetails.current.style.color = "red";
            progressBarRef.current.style.display = "none";
          } else {
            throw new Error(responseData.message || "something went wrong");
          }
        } else {
          errorDetails.current.innerHTML = "Loading..";
          errorDetails.current.style.color = "green";
          progressBarRef.current.style.display = "none";

          if (typeof Storage !== "undefined") {
            localStorage.setItem(
              "userDataSessionWay",
              JSON.stringify(responseData.body),
            );
            dispatch({ type: "CloseNavModel" });

            if (!redirect) {
              window.location.replace("/profile");
            } else {
              window.location.replace(`/${redirect}`);
            }
          } else {
            alert("please update your browser");
          }
        }
      } catch (err) {
        console.log(err);
        errorDetails.current.innerHTML =
          "Some thing went wrong try again later";
        errorDetails.current.style.color = "red";
        progressBarRef.current.style.display = "none";
        clearErrorAuto();
      }
    } else {
      errorDetails.current.innerHTML = "Please Enter Values";
      errorDetails.current.style.color = "red";
      clearErrorAuto();
    }
  };
  const clearErrorHandler = () => {
    errorDetails.current.innerHTML = "";
  };

  const clickLogin = () => {
    password.current.addEventListener("keyup", function (event) {
      // Number 13 is the "Enter" key on the keyboard
      if (event.keyCode === 13) {
        // Cancel the default action, if needed
        event.preventDefault();
        loginSubmit();
        // Trigger the button element with a click
        //document.getElementById('myBtn').click()
      }
    });
  };

  const clearErrorAuto = () => {
    setTimeout(function () {
      errorDetails.current.innerHTML = "";
    }, 5000);
  };
  const navbarMenuHandler = () => {
    // navbarMenuRef.current.style.display = 'block'
    let isDisplay = navbarMenuRef.current.style.display;
    if (isDisplay === "block") {
      navbarMenuRef.current.style.display = "none";
    } else {
      navbarMenuRef.current.style.display = "block";
    }
  };
  const searchUserHandler = async (text) => {
    let tagtype = "story_tag";

    if (history.location.pathname.split("/")[1] === "articles") {
      tagtype = "article_tag";
    } else if (
      history.location.pathname.split("/")[1] === "science-reasearch"
    ) {
      tagtype = "scires_tag";
    } else if (history.location.pathname.split("/")[1] === "experts") {
      tagtype = "expert_tag";
    } else {
      tagtype = "story_tag";
    }
    if (text) {
      let searchUser = "";
      if (chat) {
        searchUser = "searchUserChat";
      } else {
        searchUser = "searchUser";
      }
      setLoadingSearchUser(true);
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/${searchUser}?search_text=${text}&user_id=${userId}&page=1&tagtype=${tagtype}`;
      try {
        let res = await getRequestedData(url);
        dispatch({
          type: "SEARCH_USERS",
          payload: { text: text, clear: false, ...res.data.body },
        });
        setSearchSuggestions([]);
        setLoadingSearchUser(false);
      } catch (err) {
        setLoadingSearchUser(false);
        console.log(err.message);
        toast.error(err.message);
      }
    }
  };

  const searchSuggestionsHandler = async (text) => {
    if (text) {
      setLoadingUser(true);
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/searchSuggestions?search_text=${text}&page=1`;
      try {
        let res = await getRequestedData(url);
        setSearchSuggestions(res.data.body);
        setLoadingUser(false);
      } catch (err) {
        setLoadingUser(false);
        console.log(err.message);
        toast.error(err.message);
      }
    } else {
      setSearchSuggestions([]);
      dispatch({
        type: "EMPTY_SEARCH_USER",
      });
    }
  };

  const selectSearchHandler = (e) => {
    setSearchValue(e.target.innerHTML);
    setSearchSuggestions([]);
    searchUserHandler(e.target.innerHTML);
  };
  return (
    <>
      <div className="">
        {!searshBar && (
          <div
            className="reel-menu-wrapper"
            onClick={() => setDarkBackgroundToRead(!darkBackgroundToRead)}
          >
            <DropDownMenu contentClassName="reel-navbar-drop-content" />

            <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              {!isLogin ? (
                <>
                  <span
                    onClick={() => {
                      setSearchStories(true);
                      dispatch({
                        type: "EMPTY_SEARCH_USER",
                      });
                    }}
                    className="mobile-icon-search"
                  >
                    <BsSearch size={18} />
                  </span>
                </>
              ) : (
                <>
                  <span
                    onClick={() => {
                      setSearchStories(true);
                      dispatch({
                        type: "EMPTY_SEARCH_USER",
                      });
                    }}
                    className="mobile-icon-search"
                  >
                    <BsSearch size={18} />
                  </span>
                </>
              )}

              {!isLogin ? (
                <>
                  <li
                    style={{
                      display: "flex",
                      height: "25px",
                      alignItems: "center",
                      padding: "15px 10px",
                      borderRadius: "20px",
                      cursor: "pointer",
                    }}
                    className="sign-in-hover nav-item"
                  >
                    <Link
                      to="login"
                      style={{ fontWeight: "700", color: "#089cd2" }}
                    >
                      <span style={{ color: "white" }}>Sign in</span>
                      <span>
                        <HiOutlineUserCircle size={25} color="white" />
                      </span>
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <div className="nav-item">
                    {console.log("cart", cart.cartTotal)}
                    {/* 
                {cart.cartTotal > 0 && (
                  <span
                    onClick={() => {
                      history.push("/cart");
                    }}
                    className="cursor-pointer ml-2"
                  >
                    <AiOutlineShoppingCart size={20} />

                    <sup className="text-danger" style={{ fontWeight: "600" }}>
                      {cart.cartTotal}
                    </sup>
                  </span>
                )}

                {Wishlist.wishListTotal > 0 && (
                  <span
                    onClick={() => {
                      history.push("/wishlist");
                    }}
                    className="ml-2 cursor-pointer"
                  >
                    <BsHeart size={18} />

                    <sup className="text-danger" style={{ fontWeight: "600" }}>
                      {Wishlist.wishListTotal}
                    </sup>
                  </span>
                )} */}

                    <span
                      onClick={() => {
                        history.push("/profile");
                      }}
                      className="cursor-pointer ml-2"
                    >
                      <img
                        id="profile_pic_small"
                        className="profile-image"
                        src={profileImage}
                        onError={(e) => {
                          e.target.onError = null;
                          e.target.src = profileDefualt;
                        }}
                        style={{
                          height: "30px",
                          width: "30px",
                          margin: "0px",
                        }}
                        alt="profile"
                      />
                    </span>
                    <div className="dropdown-content-menu" ref={navbarMenuRef}>
                      <EditProfile
                        navbarMenuRef={navbarMenuRef}
                        logoutHandler={logoutHandler}
                        user_id={userId}
                        token={token}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {searshBar && (
          <>
            <div>
              <div
                className="display-flex-align-center"
                style={{ gap: "1rem", padding: "5px" }}
              >
                <Search
                  placeholder="Search"
                  size="large"
                  onSearch={searchUserHandler}
                  onChange={(e) => {
                    searchSuggestionsHandler(e.target.value);
                    setSearchValue(e.target.value);
                  }}
                />

                <AiOutlineClose
                  size={25}
                  className="search-close "
                  onClick={() => {
                    setSearshBar(false);
                    setSearchSuggestions([]);
                    dispatch({ type: "EMPTY_SEARCH_USER" });
                  }}
                />
              </div>

              {searchSuggestions.length > 0 && (
                <div
                  className="navbar-form navbar-left searchSuggestions"
                  style={{ width: "300px", color: "black" }}
                >
                  {searchSuggestions.map((searchSuggestion) => {
                    return (
                      <div
                        className="searchSuggestionsLists cursor-pointer"
                        onClick={(e) => {
                          selectSearchHandler(e);
                        }}
                        key={searchSuggestion._id}
                      >
                        {searchSuggestion.tag_name}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </>
        )}

        <div
          className={`${
            isNavModalOpen && !formLoginPage
              ? `modal-overlay ${isNavModalOpen && "show-modal"}`
              : ` modal-overlay-loginpage ${isNavModalOpen && "show-modal"}`
          }`}
        >
          <div
            className={`${isNavModalOpen && "modal-container"} ${
              formLoginPage && "modal-container-loginpage"
            }`}
          >
            <marquee
              className="progress-bar"
              direction="right"
              scrollamount="30"
              style={{ position: "absolute", display: "none" }}
              ref={progressBarRef}
            >
              <div className="load-bar">dsaddsadsadasdsadsa</div>
            </marquee>
            <h3 className="login-model-header">SignIn</h3>
            {!formLoginPage && (
              <button
                className="close-modal-btn"
                onClick={() => {
                  dispatch({ type: "CloseNavModel" });
                }}
              >
                <AiOutlineClose></AiOutlineClose>
              </button>
            )}
            <div className="model-body model-font-color">
              <span ref={errorDetails}></span>
              <div className="form-group-navbar">
                <BsFillPersonFill />
                <input
                  type="text"
                  className="input-model-style input-login"
                  placeholder="Username"
                  autoComplete="off"
                  ref={userName}
                  onChange={clearErrorHandler}
                />
              </div>
              <div className="form-group-navbar">
                <AiTwotoneLock />
                <input
                  type="password"
                  className="input-model-style input-login"
                  placeholder="Password"
                  autoComplete="off"
                  ref={password}
                  onChange={(clearErrorHandler, clickLogin)}
                />
              </div>
              <span
                className="model-forgot-password"
                onClick={() => {
                  dispatch({ type: "CloseNavModel" });
                }}
              >
                <Link to="user/forgot-password">Forgotten Password ?</Link>
              </span>
              <br />
              <br />
              <span className="model-forgot-password">
                <input
                  type="checkbox"
                  className="mr-1"
                  defaultChecked={isKeepLogin}
                  onChange={() => {
                    setIsKeepLogin(!isKeepLogin);
                  }}
                />
                Keep me logged in
              </span>
              <div className="btn-container">
                <button
                  id="btnSubmit"
                  type="submit"
                  name="singlebutton"
                  className="btn btn-primary fbt"
                  onClick={loginSubmit}
                >
                  Submit
                </button>
                <hr />
                <span style={{ color: "black" }}>
                  If you don't have account :{" "}
                  <Link
                    to="/register"
                    onClick={() => {
                      dispatch({ type: "CloseNavModel" });
                    }}
                  >
                    Register Here
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MapStateToProps = ({
  NavbarLogin: { isNavModalOpen, formLoginPage, userData, redirect },
  cart,
  Wishlist,
  searchUsers,
}) => {
  return {
    isNavModalOpen,
    formLoginPage,
    userData,
    cart,
    Wishlist,
    redirect,
    searchUsers,
  };
};

export default connect(MapStateToProps)(ReelNavbar);
