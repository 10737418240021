import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "../utils/sharedfunction";
import { getDataPostWithOutHeader } from "../functions/editProfile/editProfile";
import LoadingCard from "../Components/cards/LoadingCard";

const PaymentValid = ({}) => {
  const history = useHistory();
  const params = useParams();
  const query = useQuery();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    paymentmessage: true,
    trackid: "dsad",
  });

  useEffect(() => {
    setError("");
    isPaymentValid();
  }, []);

  const isPaymentValid = async () => {
    setPaymentDetails({ paymentmessage: false, trackid: "", sessionID: "" });
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/checkout/checkout-update`;
      const data = {
        trackid: params.orderid,
        resultIndicator: query.get("resultIndicator"),
      };

      console.log("input", data);
      const res = await getDataPostWithOutHeader(url, data);

      console.log(res.data.body);

      // if (
      //   res.data.body.payment === "success" &&
      //   res.data.body.itmeType === "courses"
      // ) {
      //   history.push("/mycourses");
      // } else {
      //   history.push("/chat");
      // }
      setPaymentDetails(res.data.body);

      const locationurl = new URL(window.location);

      locationurl.search = "";

      // Update the URL without reloading the page
      window.history.replaceState({}, document.title, locationurl);
    } catch (err) {
      console.log(err);
      setError(err);
      setPaymentDetails({ paymentmessage: false, trackid: "", sessionID: "" });
    }
    setLoading(false);
  };

  const gotoToNavigate = (navigate) => {
    history.push("/navigate");
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12" style={{ marginTop: "60px" }}>
          {loading && <LoadingCard count={1} />}
          {error && (
            <div className="text-danger ml-2 mt-2">
              <div className="mt-2">
                <div className="row">
                  <div className="col-md-6">
                    <div className="flex-space-btw m-2">
                      <div className="text-danger" style={{ fontSize: "16px" }}>
                        <b>Payment failed</b>
                      </div>
                      <div style={{ fontSize: "16px" }}>
                        <div>
                          <b>Your trackid: {params.orderid}</b>
                        </div>
                        <div>
                          <b>Your sessionID: {query.get("resultIndicator")}</b>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>{error.message}</div>
              <div className="mt-2">
                <b>
                  Error: if payment got debited, please contact us for support
                </b>
              </div>
            </div>
          )}
          {paymentDetails.paymentmessage && paymentDetails.trackid && (
            <div className="mt-2">
              <div className="row">
                <div className="col-md-6">
                  <div className="flex-space-btw m-2">
                    <div className="text-success" style={{ fontSize: "16px" }}>
                      <b> Payment success</b>
                    </div>
                    <div style={{ fontSize: "16px" }}>
                      <div>
                        <b>Your trackid: {paymentDetails.trackid}</b>
                      </div>
                      <div>
                        <b>Your sessionID: {paymentDetails.sessionID}</b>{" "}
                      </div>
                    </div>
                  </div>
                  <div>
                    {query.get("consultantpayid") ? (
                      <span
                        onClick={() => gotoToNavigate("chat")}
                        style={{ color: "blue" }}
                        className="ml-1 cursor-pointer"
                      >
                        Click here to goto your chat page back
                      </span>
                    ) : (
                      <span
                        onClick={() => gotoToNavigate("mycourses")}
                        style={{ color: "blue" }}
                        className="ml-1 cursor-pointer"
                      >
                        Click here to goto your course page
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentValid;
