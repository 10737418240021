import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "antd";
import { HomeOutlined, ProfileOutlined } from "@ant-design/icons";

import { MdOutlineSubscriptions } from "react-icons/md";
import {
  BsChatLeftTextFill,
  BsChatLeftText,
  BsFillPersonVcardFill,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import profileDefualt from "../images/default.jpeg";
import { useHistory } from "react-router-dom";
import { getRequestedDataWithHeader } from "../functions/editProfile/editProfile";
import courses from "../images/courses.svg";
import chat from "../images/chat.svg";
import chatSelected from "../images/chat selected.svg";
import coursesSelected from "../images/courses selected.svg";
const { SubMenu } = Menu;

const HomeNavbarVerticle = ({
  user,

  homeNavbarcollapse,

  opionSelectedKey,
}) => {
  const { Followers } = useSelector((state) => ({
    ...state,
  }));
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (user.isLogin) {
      const getFollowers = async () => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/following?user_id=${user.userData.loginUser._id}`;
        try {
          let res = await getRequestedDataWithHeader(url, user.userData.token);
          dispatch({ type: "user_followers", payload: res.data.body });
        } catch (err) {
          console.log(err.message);
        }
      };
      getFollowers();
    }
  }, [user.isLogin]);

  const toUrl = (url) => {
    let isLogin = JSON.parse(localStorage.getItem("userDataSessionWay"));
    if (isLogin) {
      if (isLogin.loginUser._id) {
        history.push(`/${url}`);
      } else {
        history.push(`/to-login/${url}`);
        //dispatch({ type: "OpenNavModel", payload: url });
      }
    } else {
      console.log(url, "url");
      history.push(`/to-login/${url}`);
      //window.location.assign(`/to-login?where=${url}`);
      //dispatch({ type: "OpenNavModel", payload: url });
    }
  };

  useEffect(() => {
    chatNotificationHandler();
  }, [user.isLogin]);

  useEffect(() => {
    // document
    //   .getElementsByClassName("ant-menu-submenu-title")[0]
    //   .addEventListener("click", () => {
    //     callSubStories();
    //   });
    // return () => {
    //   if (document.getElementsByClassName("ant-menu-submenu-title")[0]) {
    //     document
    //       .getElementsByClassName("ant-menu-submenu-title")[0]
    //       .removeEventListener("click", () => {
    //         callSubStories();
    //       });
    //   }
    // };
  }, []);

  const callSubStories = () => {
    history.push(`/`);
    dispatch({
      type: "COMPOENET_CHANGE",
      payload: {
        component: "USER_SUB_STORIES",
      },
    });
  };

  const chatNotificationHandler = async () => {
    if (user.userData) {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/chat/messageNotification?reciverId=${user.userData.loginUser._id}`;

      try {
        let res = await getRequestedDataWithHeader(url, user.userData.token);
        console.log(res);
        let notificatBox = document.getElementById("message_notification_chat");
        if (res.data.body > 0) {
          notificatBox.innerHTML = res.data.body;
          notificatBox.style.background = "#ff4d4f";
          notificatBox.style.color = "white";
          notificatBox.style.borderRadius = "50%";
          notificatBox.style.padding = "2px 4px";
          notificatBox.style.marginRight = "7px";
        } else {
          notificatBox.innerHTML = "";
          notificatBox.style = "";
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  };
  console.log(homeNavbarcollapse, "homeNavbarcollapse");
  return (
    <>
      {homeNavbarcollapse ? (
        <Menu
          style={{ height: "93vh" }}
          defaultSelectedKeys={[opionSelectedKey]}
          mode="inline"
          inlineCollapsed={homeNavbarcollapse}
        >
          <SubMenu
            key="submenu1"
            icon={<HomeOutlined style={{ fontSize: "20px" }} />}
            title={homeNavbarcollapse ? "" : "Home"}
          >
            <Menu.Item
              key="1" // Use a unique key for "Stories"
              style={{
                width: `${
                  homeNavbarcollapse
                    ? "calc(100% + 1px)"
                    : "calc(100% - 3rem + 1px)"
                }`,

                marginLeft: `${!homeNavbarcollapse && "3rem"}`,
              }}
            >
              <Link
                to="/"
                onClick={() => {
                  dispatch({ type: "EMPTY_SEARCH_USER" });
                  if (document.querySelectorAll(".list-category-carousel")[0]) {
                    document
                      .querySelectorAll(".list-category-carousel")[0]
                      .click();
                  }
                  dispatch({
                    type: "COMPOENET_CHANGE",
                    payload: {
                      component: "HOME_STORIES",
                    },
                  });
                  dispatch({
                    type: "SEARCH_USERS",
                    payload: {
                      text: "",
                      usersAndStories: [],
                      stories: [],
                      totalOverAllUsers: 0,
                      clear: true,
                    },
                  });
                }}
              >
                Stories
              </Link>
            </Menu.Item>

            <Menu.Item
              style={{
                width: `${
                  homeNavbarcollapse
                    ? "calc(100% + 1px)"
                    : "calc(100% - 3rem + 1px)"
                }`,

                marginLeft: `${!homeNavbarcollapse && "3rem"}`,
              }}
            >
              <Link
                to="/experts"
                onClick={() => {
                  dispatch({
                    type: "SEARCH_USERS",
                    payload: {
                      text: "",
                      usersAndStories: [],
                      stories: [],
                      totalOverAllUsers: 0,
                      clear: true,
                    },
                  });
                }}
              >
                Experts
              </Link>
            </Menu.Item>

            <Menu.Item
              style={{
                width: `${
                  homeNavbarcollapse
                    ? "calc(100% + 1px)"
                    : "calc(100% - 3rem + 1px)"
                }`,

                marginLeft: `${!homeNavbarcollapse && "3rem"}`,
              }}
            >
              <Link
                to="/articles"
                onClick={() => {
                  dispatch({
                    type: "SEARCH_USERS",
                    payload: {
                      text: "",
                      usersAndStories: [],
                      stories: [],
                      totalOverAllUsers: 0,
                      clear: true,
                    },
                  });
                }}
              >
                Articles
              </Link>
            </Menu.Item>

            <Menu.Item
              style={{
                width: `${
                  homeNavbarcollapse
                    ? "calc(100% + 1px)"
                    : "calc(100% - 3rem + 1px)"
                }`,

                marginLeft: `${!homeNavbarcollapse && "3rem"}`,
              }}
            >
              <Link
                to="/science-reasearch"
                onClick={() => {
                  dispatch({
                    type: "SEARCH_USERS",
                    payload: {
                      text: "",
                      usersAndStories: [],
                      stories: [],
                      totalOverAllUsers: 0,
                      clear: true,
                    },
                  });
                }}
              >
                Scientific Research
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            key="2"
            icon={<ProfileOutlined style={{ fontSize: "20px" }} />}
          >
            <Link
              to="#"
              onClick={() => {
                toUrl("profile");
              }}
            >
              My Account
            </Link>
          </Menu.Item>
          <Menu.Item
            key="3"
            icon={
              <>
                {history.location.pathname.split("/")[1] === "chat" ? (
                  <BsChatLeftTextFill
                    size={20}
                    style={{
                      marginRight: "10px",
                    }}
                  />
                ) : (
                  <BsChatLeftText
                    size={20}
                    style={{
                      marginRight: "10px",
                    }}
                  />
                )}
              </>
            }
          >
            <Link
              to="#"
              onClick={() => {
                toUrl("chat");
              }}
              style={{ width: "100%" }}
              className={`${
                homeNavbarcollapse ? "ant-color" : "ant-color-default"
              }`}
            >
              Chats
            </Link>
            <sup
              id="message_notification_chat"
              className={`${
                homeNavbarcollapse ? "ant-notify-toggle" : "ant-notify"
              }`}
            ></sup>
          </Menu.Item>

          <Menu.Item
            key="4"
            icon={
              history.location.pathname.split("/")[1] === "courses" ? (
                <img
                  src={coursesSelected}
                  style={{
                    width: "30px",
                    marginRight: "10px",
                    marginLeft: "-4px",
                  }}
                  alt="course"
                />
              ) : (
                <img
                  src={courses}
                  style={{
                    width: "30px",
                    marginRight: "10px",
                    marginLeft: "-4px",
                  }}
                  alt="course"
                />
              )
            }
          >
            <Link
              to="/courses"
              className={`${
                homeNavbarcollapse ? "ant-color" : "ant-color-default"
              }`}
            >
              Courses
            </Link>
          </Menu.Item>

          <Menu.Item
            key="5"
            icon={
              history.location.pathname.split("/")[1] === "private-groups" ? (
                <img
                  src={coursesSelected}
                  style={{
                    width: "30px",
                    marginRight: "10px",
                    marginLeft: "-4px",
                  }}
                  alt="privategroups"
                />
              ) : (
                <img
                  src={courses}
                  style={{
                    width: "30px",
                    marginRight: "10px",
                    marginLeft: "-4px",
                  }}
                  alt="privategroups"
                />
              )
            }
          >
            <Link
              to="/private-groups"
              className={`${
                homeNavbarcollapse ? "ant-color" : "ant-color-default"
              }`}
            >
              Private groups
            </Link>
          </Menu.Item>
          {/* <SubMenu
        key="sub2"
        icon={
          <MdOutlineSubscriptions
            style={{ fontSize: "20px", marginRight: "10px" }}
          />
        }
        title={homeNavbarcollapse ? "" : "Subscriptions"}
      >
        {Followers.user_followers.length > 0 &&
          Followers.user_followers.map((list, index) => {
            return (
              <Menu.Item key={index}>
                <div
                  onClick={() => {
                    dispatch({
                      type: "COMPOENET_CHANGE",
                      payload: {
                        component: "USER_SUB_STORIES",
                        user_id: list.follower_user_id._id,
                      },
                    });
                  }}
                >
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                    }}
                    src={list.follower_user_id.imageUrl}
                    alt="im-story-profile"
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = profileDefualt;
                    }}
                  />
                  <span style={{ marginLeft: "5px" }}>
                    {list.follower_user_id.user_name}
                  </span>
                </div>
              </Menu.Item>
            );
          })}
      </SubMenu> */}
        </Menu>
      ) : (
        <Menu
          style={{ height: "93vh" }}
          defaultSelectedKeys={[opionSelectedKey]}
          defaultOpenKeys={["submenu1"]} // Open the "Home" submenu by default
          mode="inline"
          inlineCollapsed={homeNavbarcollapse}
        >
          <SubMenu
            key="submenu1"
            icon={<HomeOutlined style={{ fontSize: "20px" }} />}
            title={homeNavbarcollapse ? "" : "Home"}
          >
            <Menu.Item
              key="1" // Use a unique key for "Stories"
              style={{
                width: `${
                  homeNavbarcollapse
                    ? "calc(100% + 1px)"
                    : "calc(100% - 3rem + 1px)"
                }`,

                marginLeft: `${!homeNavbarcollapse && "3rem"}`,
              }}
            >
              <Link
                to="/"
                onClick={() => {
                  dispatch({ type: "EMPTY_SEARCH_USER" });
                  if (document.querySelectorAll(".list-category-carousel")[0]) {
                    document
                      .querySelectorAll(".list-category-carousel")[0]
                      .click();
                  }
                  dispatch({
                    type: "COMPOENET_CHANGE",
                    payload: {
                      component: "HOME_STORIES",
                    },
                  });
                  dispatch({
                    type: "SEARCH_USERS",
                    payload: {
                      text: "",
                      usersAndStories: [],
                      stories: [],
                      totalOverAllUsers: 0,
                      clear: true,
                    },
                  });
                }}
              >
                Stories
              </Link>
            </Menu.Item>

            <Menu.Item
              style={{
                width: `${
                  homeNavbarcollapse
                    ? "calc(100% + 1px)"
                    : "calc(100% - 3rem + 1px)"
                }`,

                marginLeft: `${!homeNavbarcollapse && "3rem"}`,
              }}
            >
              <Link
                to="/experts"
                onClick={() => {
                  dispatch({
                    type: "SEARCH_USERS",
                    payload: {
                      text: "",
                      usersAndStories: [],
                      stories: [],
                      totalOverAllUsers: 0,
                      clear: true,
                    },
                  });
                }}
              >
                Experts
              </Link>
            </Menu.Item>

            <Menu.Item
              style={{
                width: `${
                  homeNavbarcollapse
                    ? "calc(100% + 1px)"
                    : "calc(100% - 3rem + 1px)"
                }`,

                marginLeft: `${!homeNavbarcollapse && "3rem"}`,
              }}
            >
              <Link
                to="/articles"
                onClick={() => {
                  dispatch({
                    type: "SEARCH_USERS",
                    payload: {
                      text: "",
                      usersAndStories: [],
                      stories: [],
                      totalOverAllUsers: 0,
                      clear: true,
                    },
                  });
                }}
              >
                Articles
              </Link>
            </Menu.Item>

            <Menu.Item
              style={{
                width: `${
                  homeNavbarcollapse
                    ? "calc(100% + 1px)"
                    : "calc(100% - 3rem + 1px)"
                }`,

                marginLeft: `${!homeNavbarcollapse && "3rem"}`,
              }}
            >
              <Link
                to="/science-reasearch"
                onClick={() => {
                  dispatch({
                    type: "SEARCH_USERS",
                    payload: {
                      text: "",
                      usersAndStories: [],
                      stories: [],
                      totalOverAllUsers: 0,
                      clear: true,
                    },
                  });
                }}
              >
                Scientific Research
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            key="2"
            icon={<ProfileOutlined style={{ fontSize: "20px" }} />}
          >
            <Link
              to="#"
              onClick={() => {
                toUrl("profile");
              }}
            >
              My Account
            </Link>
          </Menu.Item>
          <Menu.Item
            key="3"
            icon={
              <>
                {history.location.pathname.split("/")[1] === "chat" ? (
                  <BsChatLeftTextFill
                    size={20}
                    style={{
                      marginRight: "10px",
                    }}
                  />
                ) : (
                  <BsChatLeftText
                    size={20}
                    style={{
                      marginRight: "10px",
                    }}
                  />
                )}
              </>
            }
          >
            <Link
              to="#"
              onClick={() => {
                toUrl("chat");
              }}
              style={{ width: "100%" }}
              className={`${
                homeNavbarcollapse ? "ant-color" : "ant-color-default"
              }`}
            >
              Chats
            </Link>
            <sup
              id="message_notification_chat"
              className={`${
                homeNavbarcollapse ? "ant-notify-toggle" : "ant-notify"
              }`}
            ></sup>
          </Menu.Item>

          <Menu.Item
            key="4"
            icon={
              history.location.pathname.split("/")[1] === "courses" ? (
                <img
                  src={coursesSelected}
                  style={{
                    width: "30px",
                    marginRight: "10px",
                    marginLeft: "-4px",
                  }}
                  alt="course"
                />
              ) : (
                <img
                  src={courses}
                  style={{
                    width: "30px",
                    marginRight: "10px",
                    marginLeft: "-4px",
                  }}
                  alt="course"
                />
              )
            }
          >
            <Link
              to="/courses"
              className={`${
                homeNavbarcollapse ? "ant-color" : "ant-color-default"
              }`}
            >
              Courses
            </Link>
          </Menu.Item>

          <Menu.Item
            key="5"
            icon={
              history.location.pathname.split("/")[1] === "private-groups" ? (
                <img
                  src={coursesSelected}
                  style={{
                    width: "30px",
                    marginRight: "10px",
                    marginLeft: "-4px",
                  }}
                  alt="privategroups"
                />
              ) : (
                <img
                  src={courses}
                  style={{
                    width: "30px",
                    marginRight: "10px",
                    marginLeft: "-4px",
                  }}
                  alt="privategroups"
                />
              )
            }
          >
            <Link
              to="/private-groups"
              className={`${
                homeNavbarcollapse ? "ant-color" : "ant-color-default"
              }`}
            >
              Private groups
            </Link>
          </Menu.Item>
          {/* <SubMenu
        key="sub2"
        icon={
          <MdOutlineSubscriptions
            style={{ fontSize: "20px", marginRight: "10px" }}
          />
        }
        title={homeNavbarcollapse ? "" : "Subscriptions"}
      >
        {Followers.user_followers.length > 0 &&
          Followers.user_followers.map((list, index) => {
            return (
              <Menu.Item key={index}>
                <div
                  onClick={() => {
                    dispatch({
                      type: "COMPOENET_CHANGE",
                      payload: {
                        component: "USER_SUB_STORIES",
                        user_id: list.follower_user_id._id,
                      },
                    });
                  }}
                >
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                    }}
                    src={list.follower_user_id.imageUrl}
                    alt="im-story-profile"
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = profileDefualt;
                    }}
                  />
                  <span style={{ marginLeft: "5px" }}>
                    {list.follower_user_id.user_name}
                  </span>
                </div>
              </Menu.Item>
            );
          })}
      </SubMenu> */}
        </Menu>
      )}
    </>
  );
};

export default HomeNavbarVerticle;
